import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import moment from 'moment'
import Select from 'react-select';
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
export const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}
const ViewInitiativeData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [AllData, setAllData] = useState([]);
    const [initiativeType, setInitiativeType] = useState();
    const [isEdit, setisEdit] = useState(false);
    const [editid, seteditid] = useState("");
    const [projectName, setprojectName] = useState("");
    const initValue = {
        // initiativeTypeID: "",
        // nameOfInitaitive: "",
        // description: "",
        // startDate: "",
        // endDate: "",
        // rewardPoints: "",

        initiativeTypeID: "",
        nameOfInitaitive: "",
        description: "",
        startDate: "",
        endDate: "",
        rewardPoints: 0,
        departmentId: [],
        assignedUsers: [],
        initiativeView: ""

    }
    const [formValue, setFormValue] = useState(initValue);

    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [departmentId, setDepartmentId] = useState([]);
    const [UserDropdown, setUserDropdown] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [rewardP, setRewardP] = useState("");


    //for setting department data
    useEffect(() => {
        const data = formValue?.departmentId
        const Departmentdata = data?.map((item) => {
            const optData = DepartmentDropdown?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
    }, [DepartmentDropdown, formValue?.departmentId])


    //for setting assigned Users data
    useEffect(() => {
        const data = formValue?.assignedUsers
        const assignedUsersdata = data?.map((item) => {
            const optData = UserDropdown?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
    }, [UserDropdown, formValue?.assignedUsers])


    const getRewardData = async () => {
        const res = await HttpClient.requestData(`view-reward-point/${formValue?.initiativeTypeID}`, "GET", {});
        if (res && res?.status) {
            setRewardP(res?.data?.rewardPoints);
        }
    }

    useEffect(() => {
        getRewardData();
    }, [formValue?.initiativeTypeID]);

    // other inputs change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue(prev => ({ ...prev, [name]: value }));
    }

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "3rem"

        },

        {
            name: 'Name',
            selector: row => row.nameOfInitaitive,
            width: "22rem"

        },
        {
            name: 'Description',
            selector: row => row.description,
            width: "32rem"


        },
        {
            name: 'startDate',
            selector: row => row.startDate,

        },
        {
            name: 'endDate',
            selector: row => row.endDate,

        },
        {
            name: 'rewardPoints',
            selector: row => row.rewardPoints,

        },
        {
            name: 'Added by',
            selector: row => row.addedBy,

        },
        {
            name: 'Action',
            selector: row => row.action,
        }
    ];

    const getInititativeData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-all-initiative", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                nameOfInitaitive: item?.nameOfInitaitive,
                description: item?.description,
                startDate: moment(item?.startDate)?.format("YYYY-MM-DD"),
                endDate: moment(item?.endDate)?.format("YYYY-MM-DD"),
                rewardPoints: item?.rewardPoints,
                initiativeView: item?.initiativeView,
                departmentId: item?.departmentId,
                assignedUsers: item?.assignedUsers,
                addedBy: <div> <label>{item?.firstName}</label>&nbsp;<label>{item?.lastName}</label></div>,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    }


    const searchProject = async () => {
        let data = {}
        data = {
            searchName: projectName
        }
        const res = await HttpClient?.requestData("search-initiative", "POST", data);
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                nameOfInitaitive: item?.nameOfInitaitive,
                description: item?.description,
                startDate: moment(item?.startDate)?.format("YYYY-MM-DD"),
                endDate: moment(item?.endDate)?.format("YYYY-MM-DD"),
                rewardPoints: item?.rewardPoints,
                initiativeView: item?.initiativeView,
                departmentId: item?.departmentId,
                assignedUsers: item?.assignedUsers,
                addedBy: <div> <label>{item?.firstName}</label>&nbsp;<label>{item?.lastName}</label></div>,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    };


    useEffect(() => {
        let debounce = setTimeout(() => {
            searchProject();
        }, 400)

        return (() => clearTimeout(debounce))
    }, [projectName])

    // get initiative type data
    const getInitiativeTypeData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("view-initiative-type", "GET", {});
        console.log("resCat", res);
        if (res && res?.status) {
            setIsLoading(false);
            setInitiativeType(res?.data);
        } else {
            setIsLoading(false);
            toast.error(res?.message);
        }
    }


    // get Department Data
    const getDepartmentData = async () => {
        const res = await HttpClient.requestData("view-department", "GET", {});
        let apiData = []
        if (res && res?.status) {
            apiData = res?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        }
        setDepartmentDropdown(apiData);
    }


    // get User Data department-wise
    const getUserData = async () => {
        let data = {
            departmentArray: departmentId
        }
        const res = await HttpClient.requestData("fetch-user-department-wise", "POST", data);
        let apiData = []
        if (res && res?.status) {
            apiData = res?.data?.map((item) => ({
                label: item?.userName,
                value: item?._id,

            }));
            apiData?.unshift({ label: "All", value: "all" });
        }
        setUserDropdown(apiData);
    }


    // onchange of department data 
    const handleSelectDepartment = e => {
        setDepartmentId(e ? e?.map(option => option.value) : []);
    }

    // onchange of User data
    const handleSelectUser = e => {
        const values = e ? e.map(option => option.value) : [];
        if (values.includes("all")) {
            setAssignedUsers(UserDropdown?.filter(option => option.value !== "all")?.map(option => option.value));
        } else {
            setAssignedUsers(values);
        }
    }

    //handleupdate
    const handleUpdate = async (e) => {
        e.preventDefault();
        const data = {
            // "initiativeTypeID": formValue.initiativeTypeID,
            // "nameOfInitaitive": formValue.nameOfInitaitive,
            // "description": formValue.description,
            // "startDate": moment(formValue.startDate).format("DD-MM-YYYY"),
            // "endDate": moment(formValue.endDate).format("DD-MM-YYYY"),
            // "rewardPoints": formValue.rewardPoints

            "initiativeTypeID": formValue.initiativeTypeID,
            "nameOfInitaitive": formValue.nameOfInitaitive,
            "description": formValue.description,
            "startDate": moment(formValue.startDate).format("YYYY-MM-DD"),
            "endDate": moment(formValue.endDate).format("YYYY-MM-DD"),
            "rewardPoints": +formValue.rewardPoints,
            "initiativeView": formValue?.initiativeView,
            "departmentId": departmentId,
            "assignedUsers": assignedUsers

        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-initiative/" + editid, "PUT", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Data Updated Successfully");
            setFormValue(initValue);
            getInititativeData();
            // navigate('/manage-category');
            setIsLoading(false);
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }


    }
    //Edit Reward
    const handleEdit = async (item) => {
        setisEdit(true)
        seteditid(item?._id);
        setFormValue({
            initiativeTypeID: item?.initiativeTypeID,
            nameOfInitaitive: item?.nameOfInitaitive,
            description: item?.description,
            startDate: moment(item.startDate).format("YYYY-MM-DD"),
            endDate:  moment(item.endDate).format("YYYY-MM-DD"),
            rewardPoints: item?.rewardPoints,
            initiativeView: item?.initiativeView,
            departmentId: item?.departmentId,
            assignedUsers: item?.assignedUsers
        })
        setAssignedUsers(item?.assignedUsers)
        setDepartmentId(item?.departmentId)

    }
    //Delete part 
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-initiative/" + id, "DELETE")
            if (res && res?.status) {
                setIsLoading(false);
                getInititativeData();

                toast.success("id Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }
    useEffect(() => {
        getInititativeData();
        getInitiativeTypeData();
        getDepartmentData();
    }, [])

    useEffect(() => {
        if (departmentId.length > 0) {
            getUserData();
        }
    }, [departmentId]);
    return (
        <div className="d-flex justify-content-end">
            <div className="form-header">

                <CustomLoader loading={isLoading} />
                <section className="piechartsBox_area">
                    <div className="Search_box">
                        <form className="form-inline">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                value={projectName}
                                onChange={(e) => setprojectName(e.target.value)}
                            />
                            <button className="Search_icon" type="submit">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </form>
                    </div>

                    <div
                        style={headLineStyle}
                        className="page-headline"
                    >
                        View / Manage Projects
                    </div>
                    {
                        isEdit ?
                            // <form>
                            //     <div className="row">

                            //         <div className="col-12">
                            //             <label htmlFor="formGroupExampleInput">Select Projects Type</label>
                            //             <select
                            //                 class="form-control"
                            //                 aria-label="Default select example"
                            //                 name="initiativeTypeID"
                            //                 value={formValue?.initiativeTypeID}
                            //                 onChange={handleChange}
                            //             >
                            //                 <option value={""} disabled>Select Category</option>
                            //                 {initiativeType?.map((item, i) =>
                            //                     <option key={i} value={item?._id}>{item?.initiativetype}</option>
                            //                 )
                            //                 }
                            //             </select>
                            //         </div>

                            //         <div className="col-12">
                            //             <label htmlFor="formGroupExampleInput">Name Of Projects</label>
                            //             <input
                            //                 type="text"
                            //                 className="form-control"
                            //                 placeholder="Name Of Projects"
                            //                 name="nameOfInitaitive"
                            //                 value={formValue?.nameOfInitaitive}
                            //                 onChange={handleChange}
                            //             />
                            //         </div>
                            //         <div className="col-6 mt-2">
                            //             <label htmlFor="formGroupExampleInput">Start Date</label>
                            //             <input
                            //                 type="date"
                            //                 className="form-control"
                            //                 name="startDate"
                            //                 value={formValue?.startDate} mt-2
                            //                 onChange={handleChange}
                            //             />
                            //         </div>
                            //         <div className="col-6 mt-2">
                            //             <label htmlFor="formGroupExampleInput">End Date</label>
                            //             <input
                            //                 type="date"
                            //                 className="form-control"
                            //                 name="endDate"
                            //                 value={formValue?.endDate}
                            //                 onChange={handleChange}
                            //             />
                            //         </div>

                            //     </div>

                            //     <div className="row mt-2">
                            //         <div className="col-6">
                            //             <label htmlFor="formGroupExampleInput">Description</label>
                            //             <textarea
                            //                 type="text"
                            //                 className="form-control"
                            //                 placeholder="Description of Projects."
                            //                 name="description"
                            //                 value={formValue?.description}
                            //                 onChange={handleChange}
                            //                 rows="5"
                            //                 cols="5"
                            //             >{formValue?.description}</textarea>
                            //         </div>
                            //         <div className="col-6">
                            //             <label htmlFor="formGroupExampleInput">Reward Points</label>
                            //             <input
                            //                 type="text"
                            //                 className="form-control"
                            //                 placeholder="Add Reward Point (e.g. Number : '10'/'20' etc.)"
                            //                 name="rewardPoints"
                            //                 value={formValue?.rewardPoints}
                            //                 onChange={handleChange}
                            //             />
                            //         </div>

                            //     </div>




                            //     <div class="col-12 d-flex justify-content-end ">

                            //         <button
                            //             type="submit"
                            //             onClick={(e) => handleUpdate(e)}
                            //             class="btn btn-primaryb mt-3"
                            //             style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                            //         >
                            //             Edit Projects
                            //         </button>

                            //     </div>


                            // </form> 


                            <form>
                                <div className="row">
                                    {/* <div className="col-12">
                                        <label htmlFor="formGroupExampleInput">Select Projects Type</label>
                                        <select
                                            class="form-control"
                                            aria-label="Default select example"
                                            name="initiativeTypeID"
                                            value={formValue.initiativeTypeID}
                                            onChange={
                                                handleChange
                                            }
                                        >
                                            <option value={""} disabled>Select Category</option>
                                            {initiativeType?.map((item, i) =>
                                                <option key={i} value={item?._id} onClick={() => setmaxreward(item?.rewardPoints)}>{item?.initiativetype}</option>
                                            )
                                            }
                                        </select>
                                    </div> */}
                                    <div className="col-12">
                                        <label htmlFor="formGroupExampleInput">Select Projects Type</label>
                                        <select
                                            class="form-control"
                                            aria-label="Default select example"
                                            name="initiativeTypeID"
                                            value={formValue?.initiativeTypeID}
                                            onChange={handleChange}
                                        >
                                            <option value={""} disabled>Select Category</option>
                                            {initiativeType?.map((item, i) =>
                                                <option key={i} value={item?._id}>{item?.initiativetype}</option>
                                            )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="formGroupExampleInput">Name Of Projects</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name Of Projects"
                                            name="nameOfInitaitive"
                                            value={formValue.nameOfInitaitive}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-12">
                                        {/* <div className="col-6"> */}
                                        <label htmlFor="formGroupExampleInput">Description</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Description of Projects."
                                            name="description"
                                            value={formValue.description}
                                            onChange={handleChange}
                                            rows="5"
                                            cols="5"
                                        >{formValue.description}</textarea>
                                        {/* </div> */}
                                    </div>

                                    <div className="col-12">
                                        <p className="rsntxrpnewtxt">Select One</p>
                                        <div className="isHighLightedRadiodivnewdiv">
                                            <div className='isHighLightedRadiodivnew'>
                                                <input
                                                    type="radio"
                                                    id=""
                                                    name="initiativeView"
                                                    value="department"
                                                    checked={formValue?.initiativeView === "department"}
                                                    onChange={handleChange}

                                                />
                                                <label style={{ color: "black" }}>Department</label>
                                            </div>


                                            <div className='isHighLightedRadiodivnew'>
                                                <input
                                                    type="radio"
                                                    id=""
                                                    name="initiativeView"
                                                    value="all"
                                                    checked={formValue?.initiativeView === "all"}
                                                    onChange={handleChange}

                                                />
                                                <label style={{ color: "black" }}>All</label>
                                            </div>



                                        </div>
                                    </div>


                                    {
                                        formValue?.initiativeView === "department"
                                            ?
                                            (
                                                <>
                                                    <div className="col-12">
                                                        <p className="rsntxrpnewtxt">Department</p>

                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            className="typstrningwhselctnew"
                                                            components={animatedComponents}
                                                            defaultValue={[]}
                                                            isMulti
                                                            options={DepartmentDropdown}
                                                            onChange={handleSelectDepartment}
                                                            value={DepartmentDropdown?.filter(option => departmentId?.includes(option.value))}
                                                        />
                                                    </div>

                                                    <div className="col-12">
                                                        <p className="rsntxrpnewtxt">Select People</p>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            className="typstrningwhselctnew"
                                                            components={animatedComponents}
                                                            defaultValue={[]}
                                                            isMulti
                                                            options={UserDropdown}
                                                            onChange={handleSelectUser}
                                                            value={UserDropdown?.filter(option => assignedUsers?.includes(option.value))}
                                                        />
                                                    </div>

                                                </>
                                            )
                                            :
                                            (
                                                <></>
                                            )
                                    }

                                    <div className="col-6 mt-2">
                                        <label htmlFor="formGroupExampleInput">Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="startDate"
                                            value={moment(formValue.startDate)?.format("YYYY-MM-DD")}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="formGroupExampleInput">End Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="endDate"
                                            value={moment(formValue.endDate)?.format("YYYY-MM-DD")}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="formGroupExampleInput">Reward Points</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            // placeholder="Add Reward Point (e.g. Number : '10'/'20' etc.)"
                                            placeholder={`Reward Points should be less than or equal to ${rewardP}`}
                                            name="rewardPoints"
                                            value={formValue.rewardPoints}
                                            onChange={handleChange}
                                        />
                                    </div>


                                </div>

                                <div class="col-12 d-flex justify-content-end ">

                                    <button
                                        type="submit"
                                        onClick={(e) => handleUpdate(e)}
                                        class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Edit Projects
                                    </button>

                                </div>


                            </form>


                            : ''
                    }
                    <br />

                    <form>

                        <div>

                            <DataTable
                                columns={columns}
                                data={AllData}
                                pagination
                                striped
                                className=" rounded "
                                customStyles={customStyles}
                            />
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default ViewInitiativeData