import React, { useEffect, useState } from 'react'
import { ImCross } from "react-icons/im";
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import DataTable from 'react-data-table-component';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import moment from 'moment';
import Select from 'react-select';


const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}

const AddSurveyName = () => {
    const initValue = {
        departmentId: [],
        targetAudience: []
    }
    const [catName, setCatName] = useState()
    const [editData, setEditData] = useState({})
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [surveyStartDate, setsurveyStartDate] = useState('');
    const [surveyEndDate, setsurveyEndDate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [surveyview, setsurveyview] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setdata] = useState(initValue);
    const [deptlist, setdeptlist] = useState();
    const [deptoption, setdeptoption] = useState();
    const [targetaudoption, settargetaudoption] = useState();
    const [newuserlist, setnewuserlist] = useState([]);
    console.log(surveyStartDate, surveyEndDate, "Date")
    console.log("Data", surveyStartDate, surveyEndDate)
    //catName value
    const handleChange = (e) => {
        setCatName(e.target.value)
    }
    //Submit add data
    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();
        const startDate = new Date(surveyStartDate);
        const endDate = new Date(surveyEndDate);

        if (!catName) {
            return toast.error("Survey Name is Required");
        }
        if (!surveyStartDate) {
            return toast.error("Enter Start Date");
        }
        if (!surveyEndDate) {
            return toast.error("Enter End Date");
        }
        if (startDate > endDate) {
            return toast.error("End date cannot be before the start date");

        }



        const data1 = {
            name: catName,
            surveyStartDate: moment(surveyStartDate).format("DD/MM/YYYY"),
            surveyEndDate: moment(surveyEndDate).format("DD/MM/YYYY"),
            surveyView: surveyview,
            departmentId: data.departmentId,
            targetAudience: data.targetAudience
            // surveyNameId: surveyNameId
        }
        // console.log(data);
        // return false
        setIsLoading(true);
        const res = await HttpClient.requestData("add-survey-Name", "POST", data1);
        // console.log("resCat", res)
        // return false
        if (res && res?.status) {
            toast.success("survey name Added Successfully");
            setCatName("");
            setsurveyStartDate('');
            setsurveyEndDate('');
            getCategoryData();
            setIsLoading(false);
        } else {
            toast.error(res?.message);
            setIsLoading(false);
        }
    };

    //*************VIEW SURVEY DATA**************//

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "6rem"
        },
        {
            name: 'Survey Name',
            selector: row => row.name,
            width: "26rem"
        },
        ,
        {
            name: 'Start Date',
            selector: row => row.StartDate,
        },
        ,
        {
            name: 'End Date',
            selector: row => row.EndDate,
        },
        {
            name: 'Action',
            selector: row => row.action,
        }
    ];
    // fetch department data
    const getDepartmentData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-department", "GET", {});
        console.log("resGetCat", res)
        if (res && res?.status) {
            setdeptlist(res?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
        setdeptoption(deptlist?.map(ele => ({ value: ele?._id, label: ele?.departmentName })))

    }
    //fetch user list
    const getUserData = async () => {
        setIsLoading(true);
        const res1 = await HttpClient.requestData("last-sixmonth-user-data", "GET", {});
        console.log("resGetCat", res1)
        if (res1 && res1?.status) {
            setnewuserlist(res1?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
        // settargetaudoption(newuserlist?.map(ele => ({ value: ele?._id, label: ele?.firstName + " " + ele?.lastName })))
        console.log("resGetCat1", newuserlist?.map((obj) => obj.firstName))
    }
    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData('view-survey-Name', "GET", {});
        // console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                id: i + 1,
                sl: i + 1,
                name: item?.name,
                StartDate: item?.surveyStartDate,
                EndDate: item?.surveyEndDate,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }
        setTableData(apiData.reverse());
    }
    // edit
    const handleEdit = (item) => {
        console.log("item", item);
        setIsEdit(true)
        //navigate("/edit-category/" + id)
        window.scrollTo(0, 0)
        console.log(item, "itemmm", moment(item?.surveyStartDate).format(""), moment(item?.surveyEndDate).format("YYYY-MM-DD"))
        setEditData(item);
        setCatName(item?.name);
        setsurveyStartDate(moment(item?.surveyStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"));
        setsurveyEndDate(moment(item?.surveyEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"));
        setsurveyview(item?.surveyView);
        setdata({
            departmentId: item?.departmentId.map(option => option?._id),
            targetAudience: item?.targetAudience.map(option => option?._id)
        });

    }
    console.log("item1", surveyview);
    console.log("editData", data)
    //edit submit
    const handleEditSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();

        if (!catName) {
            return toast.error("Survey Name is Required");
        }
        if (!surveyStartDate) {
            return toast.error("Enter Start Date");
        }
        if (!surveyEndDate) {
            return toast.error("Enter End Date");
        }


        const data1 = {
            name: catName,
            surveyStartDate: moment(surveyStartDate).format("DD/MM/YYYY"),
            surveyEndDate: moment(surveyEndDate).format("DD/MM/YYYY"),
            surveyView: surveyview,
            departmentId: data.departmentId,
            targetAudience: data.targetAudience
            // surveyNameId: surveyNameId
        }
        // console.log(data);
        // return false
        setIsLoading(true);
        const res = await HttpClient.requestData("update-survey-Name/" + editData?._id, "PUT", data1);
        // console.log("resCat", res)
        // return false
        if (res && res?.status) {
            toast.success("survey name Added Successfully");
            setCatName("");
            setsurveyStartDate('')
            setsurveyEndDate('')
            // navigate('/manage-category');
            getCategoryData();
            setIsLoading(false);
            setIsEdit(false);
            setdata(initValue);
            setsurveyview(null);
        } else {
            toast.error(res?.message);
            setIsLoading(false);
        }
    };
    // delete
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-survey-Name/" + id, "DELETE")
            if (res && res?.status) {
                getCategoryData();
                toast.success("Deleted Successfully");
            } else {
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }
    const handleisCancelSubmit = (e) => {
        setIsEdit(false);
        setCatName("")
        setsurveyStartDate('')
        setsurveyEndDate('')
    }

    useEffect(() => {
        getCategoryData();
        getUserData();
        getDepartmentData();

    }, [])

    return (
        <div>
            <div className="d-flex justify-content-end">
                <CustomLoader loading={isLoading} />
                {/* form */}
                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Survey Name
                        </div>

                        <form class="row g-3 m-2">
                            <div class="col-md-12">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    Survey Name
                                </label> : <label for="inputEmail4" className="form-label ">
                                    Survey Name
                                </label>}
                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Survey Name"
                                    onChange={handleChange}
                                    value={catName}
                                    name="catName"
                                />
                            </div>
                            <div class="col-md-6">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    Start Date
                                </label> : <label for="inputEmail4" className="form-label ">
                                    Start Date
                                </label>}
                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => setsurveyStartDate(e.target.value)}
                                    value={surveyStartDate}
                                />
                            </div>
                            <div class="col-md-6">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    End Date
                                </label> : <label for="inputEmail4" className="form-label ">
                                    End Date
                                </label>}

                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="date"
                                    className="form-control"

                                    onChange={(e) => setsurveyEndDate(e.target.value)}
                                    value={surveyEndDate}

                                />
                            </div>
                            <div class="col-md-6">
                                {isEdit ? <label for="inputEmail4" className="form-label edit-tag">
                                    Survey View
                                </label> : <label for="inputEmail4" className="form-label ">
                                    Survey Viewer
                                </label>}
                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <select
                                        class="form-control"
                                        aria-label="Default select example"
                                        name="surveyView"
                                        value={surveyview}
                                        onChange={(e) => setsurveyview(e.target.value)}

                                    >

                                        <option
                                            value="all">All</option>
                                        <option
                                            onChange={(e) => setsurveyview(e.target.value)}
                                            value="department">Department</option>
                                        <option
                                            onChange={(e) => setsurveyview(e.target.value)}
                                            value="newUser">New user</option>

                                    </select>

                                </div>
                            </div>
                            {/* options */}
                            {(surveyview === "department") &&
                                <div class="col-md-6">
                                    <label for="number">Department list</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div >
                                        <Select
                                            options={deptlist?.map(ele => ({ value: ele?._id, label: ele?.departmentName }))}
                                            // defaultValue={selectedOption}
                                            value={data.departmentId ? deptlist?.filter(ele => (data?.departmentId?.includes(ele?._id))).map(ele => ({ value: ele?._id, label: ele?.departmentName })) : []}
                                            class="form-control"
                                            onChange={(selectedOptions) => {
                                                console.log(selectedOptions, "asdf");
                                                const usert = selectedOptions.map(ele => (ele.value));
                                                // setSelectedOption(selectedOptions);
                                                console.log(selectedOptions);
                                                setdata({ ...data, departmentId: usert });
                                            }}
                                            isMulti
                                            placeholder={"select depttype"}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div>
                            }
                            {/* userlist */}
                            {(surveyview === "newUser") &&
                                <div class="col-md-6 ">
                                    <label for="number">Targeted Audiance</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div >

                                        <Select
                                            options={newuserlist?.map(ele => ({ value: ele?._id, label: ele?.firstName + " " + ele?.lastName }))}
                                            defaultValue={selectedOption}
                                            value={data?.targetAudience ? data?.targetAudience?.map(ele => ({ value: ele?._id, label: ele?.firstName + " " + ele?.lastName })) : []}
                                            class="form-control"
                                            onChange={(selectedOption) => {
                                                const userIds = selectedOption?.map(option => option.value);
                                                // setSelectedOption(selectedOption);
                                                console.log('selectedoption', selectedOption)
                                                setdata({ ...data, targetAudience: userIds });
                                            }}
                                            isMulti
                                            placeholder={"select audiance"}
                                            closeMenuOnSelect={false}

                                        />
                                    </div>
                                </div>
                            }
                            {/* button  */}
                            <div class="col-12 d-flex justify-content-end ">
                                {isEdit ? <>
                                    <div className="d-flex ">

                                        <button
                                            type="submit"
                                            onClick={(e) => handleEditSubmit(e)}
                                            className="btn btn-primaryb mt-3 btn-grad"
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update Survey Name
                                        </button>
                                        <ImCross onClick={(e) => handleisCancelSubmit(e)} style={{ fontSize: "25px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />

                                    </div>


                                </>
                                    : <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Add Survey Name
                                    </button>}
                            </div>
                        </form>
                    </section>


                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View & Manage Survey List
                        </div>
                        <div
                        // className="datatable-view "
                        >
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AddSurveyName