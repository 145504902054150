import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import HttpClient from '../../utils/HttpClient';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import toast from 'react-hot-toast';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import { OpenModal } from '../../CustomComponents/openModal';
// import { FaExchangeAlt } from 'react-icons/fa';

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "11px",
    fontWeight: "bold"

}


const ViewSuggestion = () => {
    const [AllData, setAllData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    console.log(AllData, "AllData")


    // column
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "3.4rem"
        },

        //Multiple Image logic
        // {
        //     name: 'Image',
        //     cell: row => (row.image && row?.image?.map((item, i) => {
        //         console.log(item, "image")
        //         return <img alt='no image' src={item} style={{ height: "75px", width: "75px", margin: "5px" }} />
        //     })),
        //     width: "7.7rem"

        // },
        {
            name: 'Profile Image',
            cell: row => (row?.suggestionGiverIamge && <img alt='no image' src={row?.suggestionGiverIamge} style={{ height: "75px", width: "75px", margin: "5px" }} />),
            // width: "7.7rem"

        },
        {
            name: 'Name',
            selector: row => row.suggestionGiverName,
            // width: "16.6rem"
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            // width: "16.6rem"
        },
        {
            name: 'Priority',
            selector: row => row.priority,
            width: "6.6rem"
        },

        {
            name: 'Suggestions Details',
            selector: row => row.suggestion,
            // width: "5.1rem"
        },
        {
            name: 'view',
            selector: row => row.view,
            width: "6.6rem"
        },

        {
            name: 'Action',
            selector: row => row.action,
        }
    ];





    const getCategoryWiseData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-all-suggestion", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                id: item?._id,
                suggestion: item?.suggestion,
                addedBy: item?.addedBy,
                tag: item?.tag,
                tagId: item?.tagId,
                priority: item?.priority,
                designation: item?.designation,
                suggestionGiverName: item?.suggestionGiverName,
                suggestionGiverIamge: item?.suggestionGiverIamge,
                tagUserName: item?.tagUserName,
                view:
                    <i class="fa fa-eye" aria-hidden="true" onClick={(e) => handleview(item?.suggestion)}></i>
                ,
                action: <EditDeleteIcon
                    // onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }
        // Filter data based on search input



        const filteredData = apiData?.filter(item =>
            item?.priority?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
            item?.suggestionGiverName?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
            item?.designation?.toLowerCase()?.includes(searchInput?.toLowerCase())
        );
        setAllData(filteredData);



    }
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-suggestion/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getCategoryWiseData();

                toast.success("id Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }

    const handleview = (suggestion) => {
        OpenModal(suggestion);


    }
    useEffect(() => {
        getCategoryWiseData();
    }, [searchInput])

    return (
        <div>ViewSuggestion

            <div className="d-flex justify-content-end">
                <div className="form-header">

                    <CustomLoader loading={isLoading} />
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                           Received Suggestions
                        </div>
                        <div className=" d-flex justify-content-end mb-2" >
                            <div className="Search_box">
                                <form >
                                    <input
                                        className="form-control h-75"
                                        type="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    {/* <label for="inputEmail4" className="form-label"   >
                                        Priority :
                                    </label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div className=' d-flex '>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio"
                                                    class="form-check-input"
                                                    name="priority"
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                    value="low" />LOW
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio"
                                                    class="form-check-input"
                                                    name="priority"
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                    value="medium" />Medium
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio"
                                                    class="form-check-input"
                                                    name="priority"
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                    value="high" />HIGH
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="Search_icon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <form>
                            <div>
                                <DataTable
                                    columns={columns}
                                    data={AllData}
                                    pagination
                                    striped
                                    className=" rounded "
                                />
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    )
}



export default ViewSuggestion;