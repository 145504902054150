import React from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import './BudgetModal.css'

const EditBudgetModal = ({
    editid,
    closemodal,
    formvalue,
    hrEmployeeIdOptions,
    DepartmentName,
    HrName,
    getmanagedbydata,
    setDepartmentName,
    setHrName,
    setHrEmployeeIdOptions,
    setformvalue,
    DepartmentDropdown,
    setDepartmentDropdown,
    setaddmodal,
    HrDropdown,
    setHrDropdown,
    initValue,
    validate
}) => {

    //for submit functionality
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            return
        }
        const data = {
            "userId": HrName[0]?.value,
            "hrEmployeeId": hrEmployeeIdOptions,
            "departmentId": DepartmentName,
            "budget": +formvalue?.budget,
            "startOf": formvalue?.startOf,
            "endOf": formvalue?.endOf,
            // "moreAmount":formvalue?.moreAmount
        }
        setaddmodal(true);
        const res = await HttpClient.requestData("edit-budget/" + editid, "PUT", data);
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setDepartmentName(null);
            setHrName(null);
            setDepartmentDropdown([]);
            setHrDropdown([]);
            setHrEmployeeIdOptions([]);
            setformvalue(initValue)
            getmanagedbydata();
            closemodal();
        } else {
            toast.error(res?.message || "Something Wrong");
        }
    };

    const getDepartmentName = (departmentId) => {
        const department = DepartmentDropdown.find(option => option.value === departmentId)
        return department ? department?.label : '';
    };

    const getHrName = (hrId) => {
        const hr = HrDropdown?.find(option => option.value === hrId)
        return hr ? hr?.label : '';
    };

    return (
        <section className="createeventdetails" onClick={() => closemodal()}>
            <div className="detailspagecontainer" >
                <div className="createdivfrmmain">
                    <form>
                        <div className=''>

                            {/* <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">HR Name : <span>{getHrName(HrName)}</span> </p>
                            </div>

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">HR Employee ID : <span>{hrEmployeeIdOptions[0]?.value}</span> </p>
                            </div> */}

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">HR Name : <span>{getHrName(HrName[0]?.value)}</span> </p>
                            </div>

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">HR Employee ID : <span>{hrEmployeeIdOptions}</span> </p>
                            </div>

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">HR Department : <span>{getDepartmentName(DepartmentName)}</span> </p>
                            </div>

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">Alloted Amount : <span>{formvalue?.budget}</span> </p>
                            </div>

                            {/* <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">More Amount : <span>{formvalue?.moreAmount}</span> </p>
                            </div> */}

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">Start FY : <span>{formvalue?.startOf}</span> </p>
                            </div>

                            <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">End FY : <span>{formvalue?.endOf}</span> </p>
                            </div>


                            <div className="trainingpageleftdivmain">
                                <h5>Do you want to submit this? </h5>
                            </div>


                            <div className="bulletinsbmtbtndivv">
                                <div className='row' style={{ gap: "30px", marginLeft: "auto" }}>
                                    <button onClick={(e) => handleSubmit(e)}
                                        type="button" className="btn btn-success floating-message"
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        Yes
                                    </button>
                                    <button type="button" className="btn btn-danger floating-message"
                                        onClick={() => closemodal()}
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        No
                                    </button>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}

export default EditBudgetModal