import React, { useEffect, useState } from 'react';
import { ImCross } from 'react-icons/im'

import toast from 'react-hot-toast'
// import DataTable from 'react-data-table-component'

import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import HttpClient, { IMAGE_URL } from '../../utils/HttpClient'
// import { computeHeadingLevel } from '@testing-library/react'
import moment from 'moment'

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}
const customStyles = {

    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },

};


const AddEvent = () => {
    const navigate = useNavigate();
    const [AllData, setAllData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [employeeslist, setemployeelist] = useState([])
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [imageFile, setimageFile] = useState("");
    const [editData, setEditData] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [formValue, setFormValue] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [isDelete, setIsDelete] = useState(true);
    const initValue = {
        Eventname: "",
        Date: "",
        Image: "",
        Eventtype: "",
        notes: "",
        addEmploee: [],
        time: "",
        posttype: "",
        ishighlighted: "",
        affinityGroupId: ""
    }

    const [data, setdata] = useState(initValue)
    const [affData, setaffdata] = useState([]);
    const [userData, setuserdata] = useState([]);
    const [eventData, setEventData] = useState([])
    const [tableData, setTableData] = useState([]);
    const [showpubliclist, setShowpubliclist] = useState(false);
    const [showprivatelist, setShowprivatelist] = useState(false);
    console.log('first22', data)
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            // width: "3.4rem"
        },
        {
            name: 'Event Name',
            selector: row => row.Eventname,
            // width: "35.7rem"
        },
        {
            name: 'Hosted By',
            selector: row => row.HostedBy,
            // width: "20rem"
        },
        {
            name: 'Date',
            selector: row => row.Date,
            // width: "8rem"
        },
        {
            name: 'Image',
            selector: (row) => (
                <img
                    src={row.image}
                    alt="thumbnail"
                    style={{ width: "50px", height: "50px", padding: "10px" }}
                />
            ),

            // width: "8rem"
        },
        {
            name: 'Action',
            selector: row => row.action,
            // width: "8rem"
        }

    ];
    // fetch employeelist
    const getemployeedata = async () => {
        const res3 = await HttpClient.requestData("view-all-employees", "GET", {});
        console.log('user', res3)
        if (res3 && res3?.status) {
            setemployeelist(res3?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
    }
    // fetch event-type
    const getUserdata = async () => {
        const res2 = await HttpClient.requestData("fetch-event-type", "GET", {});
        console.log('user', res2)
        if (res2 && res2?.status) {
            setuserdata(res2?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
    }
    //fetch affinity data
    const getAffinitydata = async () => {
        const res = await HttpClient.requestData("view-my-affinity-group", "GET", {});
        console.log('user', res)
        if (res && res?.status) {
            setaffdata(res?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
    }
    //Form Event to Get Form Data
    const handleChange = (e) => {
        // e.target.value
        console.log(e.target.value)
        setdata({ ...data, [e.target.name]: e.target.value })

    }
    // image change
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);
        const form = new FormData();
        form.append("image", image);
        let res = await HttpClient.fileUplode("image-upload", "POST", form);
        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
            let url = res?.image;

            setdata(prev => ({ ...prev, Image: url }));
            console.log('imageurl', url);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    // validation
    const validate = () => {
        if (!data?.Eventname) {
            toast.error("EventName   is required");
            return true
        }
        // if (!data?.HostedBy) {
        //     toast.error("Hosted By required");
        //     return true
        // }
        if (!data?.Date) {
            toast.error("Date required");
            return true
        }
        // if (!data?.Image) {
        //     toast.error("Image is  required");
        //     return true
        // }
        return false
    }

    // handle submit
    const handleSubmit = async () => {

        if (validate()) {
            return
        }
        let SubmitData = {}
        if (data?.posttype === "public") {
            SubmitData = {
                eventName: data?.Eventname,
                eventTypeId: data?.Eventtype,
                eventDate: data?.Date,
                isHighLighted: data?.ishighlighted,
                postType: data?.posttype,
                notes: data?.notes,
                eventTime: data?.time,//24hrs,
                addEmploee: data?.addEmploee,
                image: data?.Image,

            }
        } else {
            SubmitData = {
                eventName: data?.Eventname,
                eventTypeId: data?.Eventtype,
                eventDate: data?.Date,
                isHighLighted: data?.ishighlighted,
                postType: data?.posttype,
                notes: data?.notes,
                eventTime: data?.time,//24hrs,
                affinityGroupId: data?.affinityGroupId,
                image: data?.Image,

            }
        }


        console.log("semit", SubmitData);
        const res = await HttpClient.requestData("add-event", "POST", SubmitData)
        // console.log("resff", res)
        if (res && res?.status) {
            toast.success("Event Added Successfully")
            getEventData();
            navigate('/manage-event')
        } else {
            toast.error(res.message || "Something Wrong")
        }

    }
    // fetch event data
    const getEventData = async () => {
        const res = await HttpClient.requestData("view-event", "GET", {})
        // console.log("resdaa", res?.data);
        if (res && res?.status) {
            setEventData(res?.data);
            const arr = res?.data?.map((item, i) => ({
                id: i + 1,
                sl: i + 1,
                Eventname: item?.eventName,
                HostedBy: item?.hostedBy,
                Date: moment(item?.eventDate).format("LL"),
                image: item?.image,
                // action: <EditDeleteIcon
                //     onClickEdit={(e) => handleEdit(item)}
                //     onClickDelete={(e) => handleDelete(item?._id)}
                // />

            }))
            console.log("arrsadfd", arr)
            setTableData(arr)
        }
    }
    // click on edit
    const handleEdit = (item) => {
        window.scrollTo(0, 0)
        setEditData(item);
        console.log("itemsd", item)
        setIsEdit(true);
        // setFormValue({
        //     eventName: item?.Eventname,
        //     hostedBy: item?.HostedBy,
        //     eventDate: item?.EventDate,
        //     image: item?.Image,
        // })
        // setimageFile(item?.image)
        setdata(
            {
                Eventname: item?.eventName,
                Date: item?.eventDate,
                Image: item?.image,
                Eventtype: item?.Eventtype,
                notes: item?.docType,
                addEmploee: [item?.addEmploee],
                time: item?.time,
                posttype: item?.posttype,
                ishighlighted: item?.ishighlighted
            }
        )
    }
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            return
        }

        const data1 = {
            eventName: data?.Eventname,
            eventTypeId: data?.Eventtype,
            eventDate: data?.Date,
            isHighLighted: data?.ishighlighted,
            postType: data?.posttype,
            notes: data?.notes,
            eventTime: data?.time,//24hrs,
            addEmploee: [data?.addEmploee],

        }
        setIsLoading(true);
        const res = await HttpClient.requestData(" edit-event/" + editData?._id, "PUT", data1);
        if (res && res?.status) {
            toast.success("Event  Added Successfully");
            // setFormValue(initValue);
            // setimageFile("")
            // setimageFile(null)
            setdata(initValue)
            setIsLoading(false);
            getEventData();
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    }
    //cancel edit
    const handleisCancelSubmit = (e) => {
        setIsEdit(false)
        setFormValue(initValue)
        // setimageFile("")
        setdata(initValue)
    }
    // delete
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-event/" + id, "PUT", {})
            if (res && res?.status) {
                setIsDelete(false);
                setIsLoading(false);
                toast.success(" Deleted Successfully");
                getEventData();
            } else {
                toast.error(res?.message || "Something Wrong");
            }
        }

        // DeleteConfirmModal(del);
    }

    useEffect(() => {
        getEventData();
        getUserdata();
        getemployeedata();
        getAffinitydata();
    }, [AllData])

    return (
        <div>
            <div className="d-flex justify-content-end">
                {/* <CustomLoader loading={isLoading} /> */}
                <div className="form-header">
                    {/* input fields */}
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {isEdit ? "Edit Question & Answer" : "Add Events"}
                        </div>
                        <form class="row g-3 m-2">
                            {/* Event Name */}
                            <div class="col-md-6 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
                                    Event Name :
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="EventName"
                                    onChange={handleChange}
                                    value={data.Eventname}
                                    name="Eventname"
                                />

                            </div>
                            {/*Date*/}
                            <div class="col-md-6 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    Date :
                                </label>

                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder=''
                                        // onChange={(e) => handleChange(e, index)}
                                        onChange={handleChange}
                                        value={data.Date}
                                        name="Date"
                                    />
                                </div>

                            </div>
                            {/* time */}
                            <div class="col-md-6 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    Event Time :
                                </label>

                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <input
                                        type="time"
                                        className="form-control"
                                        placeholder=''
                                        // onChange={(e) => handleChange(e)}
                                        onChange={handleChange}
                                        value={data.time}
                                        name="time"
                                    />
                                </div>

                            </div>
                            {/*Event type  */}
                            <div class="col-md-6 mt-2">
                                <label for="number" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>Types of Event :</label>
                                <span style={{ color: "red" }}>*</span>
                                <div className=" d-flex ">
                                    <select
                                        class="form-control"
                                        aria-label="Default select example"
                                        name="Eventtype"
                                        value={data.Eventtype}
                                        onChange={handleChange}
                                    >
                                        <option disabled>Select Type</option>
                                        {userData?.map((obj, i) => {
                                            return (
                                                <option value={obj?._id} key={i}>{obj?.typeName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {/* Post type */}
                            <div class="col-md-6 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    Share your Event:
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <div >
                                    <select
                                        class="form-control"
                                        name="posttype"
                                        value={data?.posttype}
                                        onChange={handleChange}

                                    >
                                        <option >Select Type</option>
                                        <option
                                            value="public"
                                        >Public</option>
                                        <option
                                            value="private"
                                        >Private</option>
                                    </select>
                                </div>
                            </div>

                            {/* employeeslist */}
                            {data?.posttype === "public" &&
                                <div class="col-md-6 mt-2">
                                    <label for="number">Employee Invitation</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div >
                                        {/* <select
                                            class="form-control"
                                            aria-label="Default select example"
                                            multiple={true}
                                            name="addEmploee"
                                            value={data.addEmploee}
                                            onChange={handleChange
                                            }
                                        >
                                            <option disabled>Select Type</option>
                                            {employeeslist?.map((obj, i) => {
                                                return (
                                                    <option value={obj?._id} key={i}>{obj?.firstName} {obj?.lastName}</option>
                                                )
                                            })}
                                        </select> */}
                                        <Select
                                            options={employeeslist?.map(ele => ({ value: ele?._id, label: ele?.userName }))}
                                            defaultValue={selectedOption}
                                            class="form-control"
                                            onChange={(selectedOptions) => {
                                                const employeeIds = selectedOptions.map(option => option.value);
                                                setSelectedOption(selectedOptions);
                                                setdata({ ...data, addEmploee: employeeIds });
                                            }}
                                            isMulti
                                            placeholder={"select employess"}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div>
                            }
                            {/* affinity group */}
                            {data?.posttype === "private" &&
                                <div class="col-md-6 mt-2">
                                    <label for="number">Affinity group</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div >
                                        <select
                                            class="form-control"

                                            name="affinityGroupId"
                                            value={data.affinityGroupId}
                                            onChange={handleChange}
                                        >
                                            <option >Select Type</option>
                                            {affData?.map((obj, i) => {
                                                return (
                                                    <option value={obj?._id} key={i}>{obj?.groupName}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            }


                            {/* ishighligted */}
                            <div class="col-md-6 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    Want to highlighted this event ? 
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <div class="form-check mr-2">
                                        <label class="form-check-label">
                                            <input type="radio"
                                                class="form-check-input"
                                                name="ishighlighted"
                                                onChange={handleChange}
                                                value="yes" />YES
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="radio"
                                                class="form-check-input"
                                                name="ishighlighted"
                                                onChange={handleChange}
                                                value="no" />NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* notes */}
                            <div class="col-12 mt-2">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    Add Event Details :
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder=''
                                        // onChange={(e) => handleChange(e)}
                                        onChange={handleChange}
                                        value={data.notes}
                                        name="notes"
                                        style={{
                                            rows: "5",
                                            cols: "5"
                                        }}
                                    />
                                </div>
                            </div>
                            {/*Image*/}
                            <div class="col-md-6 mt-2 ">
                                {/* <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}> */}
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
                                  Upload Event Image
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <input
                                        type="file"
                                        id="hostedBy"
                                        placeholder="Choose a photo"
                                        // className="form-control"
                                        // value={data?.Image}
                                        onChange={handleImageChange}
                                    // className="upldimagediv"
                                    />
                                </div>
                                {imageURL !== "" && (
                                    <div className="upldimgdivbulletin" >
                                        <img
                                            src={imageURL}
                                            style={{ height: "100px", width: "100px" }}
                                            alt="" />
                                        <div className="clsoedivmainrd"
                                            onClick={() => {
                                                setImageURL("");
                                                setImage("");
                                            }}>
                                            {/* <i className="fa-solid fa-xmark"></i> */}
                                            <button type='button'
                                                className=" mt-1 btn-danger p-1 rounded" style={{ marginLeft: "100px" }}
                                            >Remove</button>
                                        </div>
                                    </div>
                                )}

                                {uploading ? <p>image uploading......</p> : null}

                            </div>
                        </form>

                        {/* Button */}
                        <div class="col-12 d-flex justify-content-end ">
                            {
                                !isEdit
                                    ?
                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Add Event
                                    </button>
                                    :
                                    <div className="d-flex ">
                                        <button
                                            type="submit"
                                            onClick={(e) => handleEditSubmit(e)}
                                            className="btn  mt-3 btn-grad"
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update Event
                                        </button>
                                        <ImCross onClick={(e) => handleisCancelSubmit(e)} style={{ fontSize: "26px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
                                    </div>
                            }
                        </div>
                    </section>


                    {/* datatable */}
                    {/* <section>
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Events
                        </div>

                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                className="rounded"
                                customStyles={customStyles}
                            />
                        </div>
                    </section> */}
                </div>


            </div>

        </div>
    )
}


export default AddEvent


{/* hostedBy */ }
{/* <div class="col-md-6">
                                <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                    hostedBy-
                                </label>

                                <span style={{ color: "red" }}>*</span>
                                <div className=' d-flex '>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=''
                                        onChange={handleChange}
                                        value={data.HostedBy}
                                        name="HostedBy"
                                    />
                                </div>

                            </div> */}
// import React, {useState} from 'react';

// import {ImCross} from 'react-icons/im'
// import HttpClient from '../../utils/HttpClient';
// import toast from 'react-hot-toast';
// import Select from 'react-select';
// import CustomLoader from '../../CustomComponents/loader/CustomLoader';
// import moment from 'moment';
// import {useNavigate} from 'react-router-dom';

// const headLineStyle = {
//     textAlign: "center",
//     fontSize: "30px",
//     color: "#868e96",
//     margin: "11px",
//     fontWeight: "bold"

// }
// const AddEvent = () => {

//     const [isLoading, setIsLoading] = useState(false);
//     const [isEdit, setIsEdit] = useState(false);
//     const [imageURL, setImageURL] = useState("");
//     const initValue = {
//         // eventName: "",
//         // companyName: "",
//         // image: "",
//         Eventname: "",
//         Date: "",
//         Image: "",
//         Eventtype: "",
//         notes: "",
//         addEmploee: [],
//         time: "",
//         posttype: "",
//         ishighlighted: ""
//     }
//     const [data, setdata] = useState(initValue);
//     const [userData, setuserdata] = useState([]);
//     const [formValue, setFormValue] = useState(initValue);
//     const [eventDate, seteventDate] = useState('');
//     const [employeeslist, setemployeelist] = useState([])
//     const [imageFile, setimageFile] = useState("");
//     const [showpubliclist, setShowpubliclist] = useState(false);
//     const [showprivatelist, setShowprivatelist] = useState(false);
//     const [selectedOption, setSelectedOption] = useState(null);
//     const [image, setImage] = useState("");
//     const [uploading, setUploading] = useState(false);
//     const navigate = useNavigate()
//     console.log(formValue, eventDate, " console.log(formValue);");


//     // console.log("formValueddf", formValue)

//     // other inputs change
//     const handleChange = (e) => {
//         const { name, value } = e.target
//         setFormValue(prev => ({ ...prev, [name]: value }));
//     }

//     //cancel edit
//     const handleisCancelSubmit = (e) => {
//         setIsEdit(false)
//         setFormValue(initValue)
//         // setimageFile("")
//         setdata(initValue)
//     }


//     // validate
//     const validate = () => {
//         // Convert the input event date string to a Date object
//         const selectedDate = new Date(eventDate);
//         // Get the current date
//         const currentDate = new Date();
//         // Set hours, minutes, seconds, and milliseconds of currentDate to 0
//         currentDate.setHours(0, 0, 0, 0);


//         if (!formValue?.eventName) {
//             toast.error("Event Name is required");
//             return true
//         }
//         if (!formValue?.companyName) {
//             toast.error("Company Name Name is required");
//             return true
//         }
//         if (!eventDate) {
//             toast.error("Event Date is required");
//             return true
//         }
//         if (selectedDate < currentDate) {
//             toast.error("Event Date cannot be in the past");
//             return true;
//         }
//         if (!formValue?.image) {
//             toast.error("Image is required");
//             return true
//         }
//         return false
//     }

//     // submit
//     const handleSubmit = async (e) => {
//         // console.log("valuesdd");
//         // console.log("formValue",formValue);
//         e.preventDefault();

//         if (validate()) {
//             return
//         }

//         const data = {
//             "eventName": formValue.eventName,
//             "image": formValue.image,
//             "hostedBy": formValue.companyName,
//             "eventDate": moment(eventDate).format("YYYY-MM-DD"),


//         }
//         setIsLoading(true);
//         const res = await HttpClient.requestData("add-event", "POST", data);
//         // console.log("resCat", res)
//         if (res && res?.status) {
//             toast.success("Event Uploaded Successfully");
//             setFormValue(initValue);
//             setimageFile("")
//             seteventDate('')
//             navigate('/manage-event');
//             setIsLoading(false);
//         } else {
//             toast.error("Event Name Already Exists , Please Try A Different One");
//             setIsLoading(false);
//         }
//     };






//     const handleImageChange = async (e) => {
//         // console.log("e",e.target.files);
//         setimageFile(URL.createObjectURL(e.target.files[0]))
//         let formData = new FormData()

//         formData.append("image", e.target.files[0])
//         // setIsLoading(true);
//         const res = await HttpClient.fileUplode("image-upload", "POST", formData);
//         // console.log("image", res)
//         // let img = "https://elites3bkt.s3.ap-south-1.amazonaws.com/image/c0cd7570-6e55-11ee-b35b-6f74a2fba80f.png"
//         // setFormValue({...formValue,image:img})
//         if (res && res?.status) {
//             toast.success("Image Uploaded Successfully");
//             setFormValue({ ...formValue, image: res?.image })
//             setIsLoading(false);
//         } else {
//             toast.error(res?.message || "Something Wrong");
//             setIsLoading(false);
//         }


//     }

//     return (
//         <div>

//             <CustomLoader loading={isLoading} />


//             <div className="d-flex justify-content-end">

//                 <div className="form-header">
//                     <section className="piechartsBox_area">
//                         <div
//                             style={headLineStyle}
//                             className="page-headline"
//                         >
//                             Add Event
//                         </div>
//                         {/* <form> */}
//                         {/* <div className="row">
//                                 <div className="col">
//                                     <label htmlFor="formGroupExampleInput" className='edit-tag'>
//                                         Event Name
//                                         <span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Event Name"
//                                         name="eventName"
//                                         value={formValue.eventName}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div className="col">
//                                     <label htmlFor="formGroupExampleInput" className='edit-tag'>Company Name
//                                         <span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Company Name"
//                                         name="companyName"
//                                         value={formValue.companyName}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                                 <div class="col-12 mt-2">
//                                     <label for="inputEmail4" class="form-label edit-tag">
//                                         Event Date
//                                     </label>
//                                     <span style={{ color: "red" }}>*</span>
//                                     <input
//                                         type="date"
//                                         className="form-control w-50"

//                                         onChange={(e) => seteventDate(e.target.value)}
//                                         value={eventDate}

//                                     />
//                                 </div>

//                             </div> */}
//                         {/* <div className="row" style={{ marginTop: "10px" }}>
//                                 <div className="col ">
//                                     <label htmlFor="formGroupExampleInput" className='edit-tag'>Image*: </label>
//                                     <input
//                                         className='mx-1'
//                                         type="file"
//                                         onChange={handleImage}
//                                     />
//                                     <br></br>
//                                     {imageFile && (
//                                         <div >
//                                             <img
//                                                 alt="not found"
//                                                 width={"250px"}
//                                                 src={imageFile}
//                                             />
//                                             <br />
//                                             <button onClick={() => setimageFile(null)}
//                                                 className='btn btn-danger btn-grad-cancel mt-1'
//                                                 style={{ marginLeft: "167px" }}>Remove</button>
//                                         </div>
//                                     )}
//                                 </div>

//                             </div> */}

//                         {/* <div class="col-12 d-flex justify-content-end ">

//                                 <button
//                                     type="submit"
//                                     onClick={(e) => handleSubmit(e)}
//                                     class="btn btn-primaryb mt-3"
//                                     style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
//                                 >
//                                     Add Event
//                                 </button>

//                             </div> */}
//                         {/* </form> */}
//                         <form class="row g-3 m-2">
//                             {/* Event Name */}
//                             <div class="col-md-6 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
//                                     Event Name :
//                                 </label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="EventName"
//                                     onChange={handleChange}
//                                     value={data.Eventname}
//                                     name="Eventname"
//                                 />

//                             </div>
//                             {/*Date*/}
//                             <div class="col-md-6 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     Date :
//                                 </label>

//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <input
//                                         type="date"
//                                         className="form-control"
//                                         placeholder=''
//                                         // onChange={(e) => handleChange(e, index)}
//                                         onChange={handleChange}
//                                         value={data.Date}
//                                         name="Date"
//                                     />
//                                 </div>

//                             </div>
//                             {/* time */}
//                             <div class="col-md-6 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     Event Time :
//                                 </label>

//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <input
//                                         type="time"
//                                         className="form-control"
//                                         placeholder=''
//                                         // onChange={(e) => handleChange(e)}
//                                         onChange={handleChange}
//                                         value={data.time}
//                                         name="time"
//                                     />
//                                 </div>

//                             </div>
//                             {/*Event type  */}
//                             <div class="col-md-6 mt-2">
//                                 <label for="number" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>Types of Event :</label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=" d-flex ">
//                                     <select
//                                         class="form-control"
//                                         aria-label="Default select example"
//                                         name="Eventtype"
//                                         value={data.Eventtype}
//                                         onChange={handleChange}
//                                     >
//                                         <option disabled>Select Type</option>
//                                         {userData?.map((obj, i) => {
//                                             return (
//                                                 <option value={obj?._id} key={i}>{obj?.typeName}</option>
//                                             )
//                                         })}
//                                     </select>
//                                 </div>
//                             </div>
//                             {/* Post type */}
//                             <div class="col-md-6 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     Share your Event:
//                                 </label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <div class="form-check">
//                                         <label class="form-check-label">
//                                             <input type="radio"
//                                                 class="form-check-input"
//                                                 name="posttype"
//                                                 onChange={handleChange}
//                                                 value="public"
//                                                 onClick={() => {
//                                                     setShowpubliclist(true);
//                                                     setShowprivatelist(false);
//                                                 }} />Public

//                                         </label>
//                                     </div>
//                                     <div class="form-check">
//                                         <label class="form-check-label">
//                                             <input type="radio"
//                                                 class="form-check-input"
//                                                 name="posttype"
//                                                 onChange={handleChange}
//                                                 onClick={() => {
//                                                     setShowprivatelist(true);
//                                                     setShowpubliclist(false)
//                                                 }

//                                                 }
//                                                 value="private" />Private
//                                         </label>
//                                     </div>
//                                 </div>

//                             </div>
//                             {/* employeeslist */}
//                             {showpubliclist &&
//                                 <div class="col-md-4 mt-2">
//                                     <label for="number">Employee list</label>
//                                     <span style={{ color: "red" }}>*</span>
//                                     <div className=" d-flex ">
//                                         {/* <select
//                                             class="form-control"
//                                             aria-label="Default select example"
//                                             multiple={true}
//                                             name="addEmploee"
//                                             value={data.addEmploee}
//                                             onChange={handleChange
//                                             }
//                                         >
//                                             <option disabled>Select Type</option>
//                                             {employeeslist?.map((obj, i) => {
//                                                 return (
//                                                     <option value={obj?._id} key={i}>{obj?.firstName} {obj?.lastName}</option>
//                                                 )
//                                             })}
//                                         </select> */}
//                                         <Select
//                                             options={employeeslist?.map(ele => ({ value: ele?._id, label: ele?.userName }))}
//                                             defaultValue={selectedOption}
//                                             class="form-control"
//                                             onChange={(selectedOptions) => {
//                                                 const employeeIds = selectedOptions.map(option => option.value);
//                                                 setSelectedOption(selectedOptions);
//                                                 setdata({ ...data, addEmploee: employeeIds });
//                                             }}
//                                             isMulti
//                                             placeholder={"select employess"}
//                                             closeMenuOnSelect={false}
//                                         />
//                                     </div>
//                                 </div>
//                             }
//                             {/* hostedBy */}
//                             {/* <div class="col-md-6">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     hostedBy-
//                                 </label>

//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder=''
//                                         onChange={handleChange}
//                                         value={data.HostedBy}
//                                         name="HostedBy"
//                                     />
//                                 </div>

//                             </div> */}

//                             {/* ishighligted */}
//                             <div class="col-md-6 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     IsHighlighted :
//                                 </label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <div class="form-check">
//                                         <label class="form-check-label">
//                                             <input type="radio"
//                                                 class="form-check-input"
//                                                 name="ishighlighted"
//                                                 onChange={handleChange}
//                                                 value="yes" />YES
//                                         </label>
//                                     </div>
//                                     <div class="form-check">
//                                         <label class="form-check-label">
//                                             <input type="radio"
//                                                 class="form-check-input"
//                                                 name="ishighlighted"
//                                                 onChange={handleChange}
//                                                 value="no" />NO
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* notes */}
//                             <div class="col-12 mt-2">
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
//                                     Event Description :
//                                 </label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <textarea
//                                         type="text"
//                                         className="form-control"
//                                         placeholder=''
//                                         // onChange={(e) => handleChange(e)}
//                                         onChange={handleChange}
//                                         value={data.notes}
//                                         name="notes"
//                                         style={{
//                                             rows: "5",
//                                             cols: "5"
//                                         }}
//                                     />
//                                 </div>
//                             </div>
//                             {/*Image*/}
//                             <div class="col-md-6 mt-2 ">
//                                 {/* <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}> */}
//                                 <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
//                                     Image
//                                 </label>
//                                 <span style={{ color: "red" }}>*</span>
//                                 <div className=' d-flex '>
//                                     <input
//                                         type="file"
//                                         id="hostedBy"
//                                         placeholder="Choose a photo"
//                                         // className="form-control"
//                                         // value={data?.Image}
//                                         onChange={handleImageChange}
//                                     // className="upldimagediv"
//                                     />
//                                 </div>
//                                 {imageURL !== "" && (
//                                     <div className="upldimgdivbulletin" >
//                                         <img
//                                             src={imageURL}
//                                             style={{ height: "100px", width: "100px" }}
//                                             alt="" />
//                                         <div className="clsoedivmainrd"
//                                             onClick={() => {
//                                                 setImageURL("");
//                                                 setImage("");
//                                             }}>
//                                             <i className="fa-solid fa-xmark"></i>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {uploading ? <p>image uploading......</p> : null}

//                             </div>
//                         </form>
//                         {/* Button */}
//                         <div class="col-12 d-flex justify-content-end ">
//                             {
//                                 !isEdit
//                                     ?
//                                     <button
//                                         type="submit"
//                                         onClick={(e) => handleSubmit(e)}
//                                         class="btn btn-primaryb mt-3"
//                                         style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
//                                     >
//                                         Add Event
//                                     </button>
//                                     :
//                                     <div className="d-flex ">
//                                         <button
//                                             type="submit"
//                                             // onClick={(e) => handleEditSubmit(e)}
//                                             className="btn  mt-3 btn-grad"
//                                         //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
//                                         >
//                                             Update Event
//                                         </button>
//                                         <ImCross onClick={(e) => handleisCancelSubmit(e)} style={{ fontSize: "26px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
//                                     </div>
//                             }
//                         </div>
//                     </section>
//                 </div>
//             </div >


//         </div >

//     )
// }

// export default AddEvent
{/* button */ }

{/* <div class="col-12 d-flex justify-content-between  ">
                            <div>
                                <button
                                    onClick={() => handleSubmit()}

                                    class="btn btn-primaryb mt-3"
                                    style={{ background: "linear-gradient(195deg, rgb(150, 86, 44), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    Add Event
                                </button>
                            </div>

                        </div> */}












