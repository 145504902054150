import Swal from "sweetalert2"

export const OpenModal = (suggestion) => {
    Swal.fire({

        text: suggestion,
        // icon: 'warning',
        // showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33'
        // confirmButtonText: 'Delete'
    })
        .then((result) => {
            // if (result.isConfirmed) {
            //     // deleteFunction();
            //     // Swal.fire(
            //     //     'Deleted!',
            //     //     'Your file has been deleted.',
            //     //     'success'
            //     // )
            // }
            console.log("done")
        })
}