import React, { useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import './BudgetModal.css'

const Budgetmoremodal = ({ closemodal,
    getmanagedbydata,
    moreId
}) => {
    const [moreAmount, setMoreAmount] = useState(0);

    //for submit functionality
    const handleMore = async () => {
        let data = {
            moreAmount: +moreAmount
        }
        const res = await HttpClient?.requestData(`add-more-budget/${moreId}`, "POST", data);
        if (res && res?.status) {
            toast.success("More budget Successfully");
            setMoreAmount(0);
            getmanagedbydata();
        }
    }



    return (
        <section className="createeventdetails" >
            <div className="detailspagecontainer" >
                <div className="createdivfrmmain">
                    <form>
                        <div className=''>
                            {/* <div className="trainingpageleftdivmain">
                                <p className="rsntxrpnewtxt">Alloted Amount : <span>{formvalue?.budget}</span> </p>
                            </div> */}

                            <div className="col">
                                <label htmlFor="formGroupExampleInput">More Alloted Amount:
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    name="moreAmount"
                                    value={moreAmount}
                                    onChange={(e) => setMoreAmount(e.target.value)}
                                />
                            </div>

                            <div className="trainingpageleftdivmain">
                                <h5>Do you want to submit this? </h5>
                            </div>

                            <div className="bulletinsbmtbtndivv">
                                <div className='row' style={{ gap: "30px", marginLeft: "auto" }}>
                                    <button onClick={() => handleMore()}
                                        type="button" className="btn btn-success floating-message"
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        Yes
                                    </button>
                                    <button type="button" className="btn btn-danger floating-message"
                                        onClick={() => closemodal()}
                                        data-toggle="tooltip" data-placement="top" title="Click to change">
                                        No
                                    </button>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}

export default Budgetmoremodal