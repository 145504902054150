import Swal from "sweetalert2"

export const LogoutConfirmModal = (logoutFunction) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You will be loggedout!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout'
    }).then((result) => {
        if (result.isConfirmed) {
            logoutFunction();
            // Swal.fire(
            //     'Deleted!',
            //     'Your file has been deleted.',
            //     'success'
            // )
        }
    })
}