import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FaStarOfLife } from 'react-icons/fa';
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "15px",
    fontWeight: "bold"

}


const Updatepassword = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [uploading, setUploading] = useState(false);
    //const [EmpData, setEmpData] = useState([]);
    const initValue = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
        // email: "",
        // password: "",
        // address: "",
        // gender: "",
        // education: "",
        // dob: "",

    }
    const [formValue, setFormValue] = useState(initValue);
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue(prev => ({ ...prev, [name]: value }));
    }
    // image change
    // const handleImageChange = async (e) => {
    //     let image = e.target.files[0];
    //     setUploading(true);
    //     const form = new FormData();
    //     form.append("image", image);

    //     let res = await HttpClient.fileUplode("image-upload", "POST", form);
    //     setUploading(false);
    //     if (res.status) {
    //         toast.success("Image uploaded successfully");
    //         setFormValue(prev => ({ ...prev, image: res?.image }))

    //         // setImageURL(res?.image);
    //     } else {
    //         toast.error(res?.message || "Error uploading image");
    //     }
    // };
    const validate = () => {
        if (!formValue?.oldPassword) {
            toast.error("firstName is required");
            return true
        }
        if (formValue?.newPassword !== formValue?.confirmPassword) {
            toast.error("same password is required");
            return true
        }

        // if (!formValue?.email) {
        //   toast.error("email is required");
        //   return true
        // }
        // if (!formValue?.password) {
        //   toast.error("password is required");
        //   return true
        // }
        // if (!formValue?.phoneNo) {
        //   toast.error("phoneNo is required");
        //   return true
        // }

        // if (!formValue?.image) {
        //   toast.error("Image is required");
        //   return true
        // }

        return false
    }


    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();

        if (validate()) {
            return
        }

        const data = {
            "oldPass": formValue?.oldPassword,
            "newPass": formValue?.newPassword

        }
        setIsLoading(true);
        const res = await HttpClient.requestData("edit-pass", "POST", data);
        console.log("resCat1", res.status)
        if (res && res?.success) {
            toast.success("updated Successfully");
            setFormValue(initValue);
            //    navigate('/');
            setIsLoading(false);
            console.log(data, "apiData")
        } else {
            toast.error(res?.message || "Something Wrong");

            setIsLoading(false);
        }
    };

    // getprofile
    // const getProfile = async () => {
    //     const res = await HttpClient.requestData("get-profile", "GET");
    //     console.log(res?.data, "profile");
    //     if (res && res?.status) {
    //         setFormValue({
    //             firstName: res?.data?.firstName,
    //             lastName: res?.data?.lastName,
    //             image: res?.data?.image,
    //             phoneNo: res?.data?.contact
    //         });

    //     } else {
    //     }
    // };
    // useEffect(() => {
    //     getProfile();
    // }, []);

    return (
        <div className="d-flex justify-content-end">
            <div className="form-header">
                <section className="piechartsBox_area">
                    <div
                        style={headLineStyle}
                        className="page-headline"
                    >
                        Update Password <hr />
                    </div>

                    <form>
                        <div className="row">



                            <div className="col-md-6 mt-4 ">
                                <label htmlFor="formGroupExampleInput">Old Password<span className="text-danger">&nbsp;*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Old Password"
                                    name="oldPassword"
                                    value={formValue.oldPassword}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6 mt-3 ">
                                <label htmlFor="formGroupExampleInput">New Password<span className="text-danger">&nbsp;*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="New Password"
                                    name="newPassword"
                                    value={formValue.newPassword}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 mt-3 ">
                                <label htmlFor="formGroupExampleInput">Confirm Password<span className="text-danger">&nbsp;*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="confirm Password"
                                    name="confirmPassword"
                                    value={formValue?.confirmPassword}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="formGroupExampleInput">
                                    Image<span className="text-danger">&nbsp;*</span>
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    // value={image}
                                    accept="image/*"
                                    name="image"
                                    onChange={handleImageChange}
                                />

                                {
                                    uploading &&
                                    <p>image uploading......</p>
                                }
                                {
                                    formValue?.image !== "" &&
                                    <div className="imageuplddiv">
                                        <img src={formValue?.image} alt="" />
                                        <div
                                            className="imageupldcrs"
                                            onClick={() => {
                                                setFormValue(prev => ({ ...prev, image: "" }))
                                            }}
                                        >
                                            <i class="fa-solid fa-xmark"></i>
                                        </div>
                                    </div>
                                }
                            </div> */}
                        </div>
                    </form>


                    {/* Button */}
                    <div className="col-12 d-flex justify-content-end ">

                        {
                            !isEdit
                                ?
                                <button
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                    class="btn btn-primaryb mt-3"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    Update password
                                </button>
                                :
                                <button
                                    type="submit"
                                    // onClick={(e) => handleEditSubmit(e)}
                                    class="btn btn-primaryb mt-3"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    Update
                                </button>
                        }

                    </div>
                </section>
            </div>
        </div>
    )
}



export default Updatepassword
