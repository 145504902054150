import React, { useEffect, useState } from "react";
import CustomLoader from "../CustomComponents/loader/CustomLoader";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import EditDeleteIcon from "../CustomComponents/EditDeleteIcon";
import HttpClient from "../utils/HttpClient";
import { DeleteConfirmModal } from "../CustomComponents/DeleteConfirmModal";
import Select from 'react-select';
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold",
};

const customStyles = {
    // rows: {
    //     style: {
    //         minHeight: '48px', // override the row height
    //     },
    // },
    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            backgroundColor: "#cee0eb", // set the background color for head cells
        },
    },
    // cells: {
    //     style: {
    //         paddingLeft: '8px', // override the cell padding for data cells
    //         paddingRight: '8px',
    //     },
    // },
};

const TrainingCat = () => {
    const initValue = {
        name: "",
        rewardPoint: "",
        viewUserType: []
    };
    const userlist = ["HR", "Manager", "Employee"];
    const [selectedOption, setSelectedOption] = useState(null);
    const [formvalue, setformvalue] = useState(initValue);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [userData, setuserData] = useState();
    //Edit
    const [editID, setEditID] = useState('');
    console.log("editID", editID);
    const [isEdit, setIsEdit] = useState(false);



    //view table
    const columns = [
        {
            name: "SL",
            selector: (row) => row.sl,
        },
        {
            name: " Name",
            selector: (row) => row.name,
        },
        {
            name: "Points",
            selector: (row) => row.rewardPoint,
        },
        {
            name: "user type",
            selector: (row) => row.viewUserType,
        },
        {
            name: "Action",
            selector: (row) => row.action,
        },
    ];

    const gettrainingcatData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-training-category", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                name: item?.name,
                rewardPoint: item?.rewardPoint,
                viewUserType:
                    <span>
                        {item?.viewUserType?.slice(0, -1).join(", ")
                            + (item?.viewUserType?.length > 1 ? "," : "")
                            + (item?.viewUserType?.length > 1 ? " and " : "")
                            + item?.viewUserType?.slice(-1)}
                    </span>,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }
            ));
        } else {
            setIsLoading(false);
        }

        setTableData(apiData);
    }

    //delete point

    const handleDelete = (id) => {

        const del = async () => {
            console.log(id, "id")
            setIsLoading(true)
            const res = await HttpClient.requestData("delete-training-category/" + id, "PUT", {})
            if (res && res.status) {
                setIsLoading(false)
                toast.success("Deleted Succesfully")
                gettrainingcatData();
            }
            else {
                setIsLoading(false)
                toast.error("Failed to Delete")
            }
        }
        DeleteConfirmModal(del);
    }

    // handle edit
    const handleEdit = async (item) => {
        setEditID(item?._id);
        console.log(item, "itemID:", item?._id);
        setIsEdit(true);
        setformvalue({
            name: item?.name,
            rewardPoint: item?.rewardPoint,
            viewUserType: item?.viewUserType
        });

        // setClaimName({ ...claimName, activity: item?.activity, reward: item?.point });


    }


    //change state values

    const handleChange = (e) => {
        const { name, value } = e.target
        setformvalue(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        if (!formvalue?.name) {
            return toast.error(" Name is Required");
        }

        if (!formvalue?.rewardPoint) {
            return toast.error("Reward Point is Required");
        }
        if (!formvalue?.viewUserType) {
            return toast.error("enter userType is Required");
        }


        const data = {
            "name": formvalue?.name,
            "rewardPoint": formvalue?.rewardPoint,
            "viewUserType": formvalue?.viewUserType

        }
        // setClaimName(claimValue);
        console.log("thereward", data);

        const res = await HttpClient.requestData("add-training-category", "POST", data);

        if (res && res.status) {
            setIsLoading(false)
            setformvalue(initValue);
            toast.success(`Target Category Added Successfully`);
            gettrainingcatData();
            setSelectedOption(null)
        }
        else {
            setIsLoading(false)
            toast.error("Something Failed!");
        }
    };



    const handleisEditSubmit = async (e, id) => {
        e.preventDefault();
        setIsLoading(true)

        // if (!formvalue?.) {
        //     return toast.error("Activity Name is Required");
        // }

        // if (!claimName.reward) {
        //     return toast.error("Reward Point is Required");
        // }


        const data = {

            "name": formvalue?.name,
            "rewardPoint": formvalue?.rewardPoint,
            "viewUserType": formvalue?.viewUserType


        }
        // setClaimName(claimValue);
        console.log("thereward", data);

        const res = await HttpClient.requestData("update-training-category/" + editID, "PUT", data);

        if (res && res.status) {
            setIsLoading(false)

            toast.success(`Points Edited Successfully`);
            setIsEdit(false)
            gettrainingcatData();
            setformvalue(initValue)
        }
        else {
            setIsLoading(false)
            toast.error('Editing failed')
        }
    };

    // cancel edit
    const handleCancel = () => {
        setIsEdit(false)

        setformvalue(initValue)
    };


    useEffect(() => {
        gettrainingcatData();
    }, [])

    return (
        <div>
            <div className="d-flex justify-content-end ">
                <CustomLoader loading={isLoading} />

                {/* form */}
                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div style={headLineStyle} className="page-headline">
                            {isEdit ? " Edit Training Category" : " Add Training Category"}
                        </div>

                        <form class="row m-2">
                            <div class="col-6">
                                <label
                                    for="inputEmail4"
                                    className={isEdit ? "edit-tag" : "form-label"}
                                >
                                    Training category
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Training Category Name"
                                    onChange={handleChange}
                                    value={formvalue?.name}
                                    name="name"
                                    id="inputEmail4"
                                />
                            </div>
                            <div class="col-6">
                                <label
                                    for="inputEmail4"
                                    className={isEdit ? "edit-tag" : "form-label"}
                                >
                                    Reward Point
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Reward Point"
                                    onChange={handleChange}
                                    value={formvalue?.rewardPoint}
                                    name="rewardPoint"
                                    id="inputEmail4"
                                />
                            </div>
                            <div class="col-6 mt-2 ">
                                <label for="number">Select User type</label>
                                <span style={{ color: "red" }}>*</span>
                                <div className="d-flex">
                                    <Select
                                        options={userlist?.map(ele => ({ value: ele, label: ele }))}
                                        // defaultValue={selectedOption}
                                        value={formvalue?.viewUserType ? formvalue?.viewUserType?.map(ele => ({ value: ele, label: ele })) : []}
                                        class="form-control"
                                        onChange={(selectedOptions) => {
                                            console.log(selectedOptions, "asdf");
                                            const usert = selectedOptions.map(option => option.value);
                                            // setSelectedOption(selectedOptions);
                                            console.log(selectedOptions);
                                            setformvalue({ ...formvalue, viewUserType: usert });
                                        }}
                                        isMulti
                                        placeholder={"select Usertype"}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>

                            <div class=" col-3 mt-3 d-flex justify-content-end ">
                                {isEdit ? (
                                    <div className=" d-flex ">
                                        <button
                                            type="submit"
                                            onClick={(e) => handleisEditSubmit(e)}
                                            className="btn btn-primaryb mt-3 btn-grad"
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update list
                                        </button>{" "}
                                        <button
                                            type="submit"
                                            onClick={(e) => handleCancel(e)}
                                            className="btn btn-danger mt-3 btn-grad-cancel px-2 mx-4"
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            {" "}
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        className="btn btn-primaryb mt-3"
                                        style={{
                                            background:
                                                "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                                            color: "#fff",
                                        }}
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </form>
                    </section>
                </div>
            </div>

            {/* table */}
            <div className="datatable-view ">
                <div style={headLineStyle} className="page-headline">
                    View & Manage Claiming Point
                </div>

                <DataTable
                    columns={columns}
                    data={tableData}
                    pagination
                    striped
                    customStyles={customStyles}
                />
            </div>
        </div>
    );
};

export default TrainingCat;