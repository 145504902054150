import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}

const AddInitiativeData = () => {
    const [isLoading, setIsLoading] = useState(false);

    const initValue = {
        initiativeTypeID: "",
        nameOfInitaitive: "",
        description: "",
        startDate: "",
        endDate: "",
        rewardPoints: 0,
        departmentId: [],
        assignedUsers: [],
        initiativeView: ""
    }
    const [maxreward, setmaxreward] = useState();
    const [formValue, setFormValue] = useState(initValue);
    const [initiativeType, setInitiativeType] = useState();
    const [deptlist, setdeptlist] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    console.log(formValue, "rr")

    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [departmentId, setDepartmentId] = useState([]);
    const [UserDropdown, setUserDropdown] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [rewardP, setRewardP] = useState("");

    const getRewardData = async () => {
        const res = await HttpClient.requestData(`view-reward-point/${formValue?.initiativeTypeID}`, "GET", {});
        if (res && res?.status) {
            setRewardP(res?.data?.rewardPoints);
        }
    }

    useEffect(() => {
        getRewardData();
    }, [formValue?.initiativeTypeID]);


    //checking form validaion
    // validate
    const validate = () => {
        const startDate = new Date(formValue.startDate);
        const endDate = new Date(formValue.endDate);

        if (!formValue?.initiativeTypeID) {
            toast.error("Inititative type is required");
            return true
        }
        if (!formValue?.nameOfInitaitive) {
            toast.error("name of initiative is required");
            return true
        }

        if (!formValue?.startDate) {
            toast.error("Start date required");
            return true
        }
        if (!formValue?.endDate) {
            toast.error("End date required");
            return true
        }
        if (startDate > endDate) {
            toast.error("End date cannot be before the start date");
            return true;
        }
        if (!formValue?.description) {
            toast.error("description required");
            return true
        }
        if (!formValue?.rewardPoints <= maxreward) {
            toast.error("Reward point required");
            return true
        }


        return false
    }

    // other inputs change
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue(prev => ({ ...prev, [name]: value }));
    }

    // get initiative type data
    const getInitiativeTypeData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("view-initiative-type", "GET", {});
        console.log("resCat", res);
        if (res && res?.status) {
            setIsLoading(false);
            setInitiativeType(res?.data);
        } else {
            setIsLoading(false);
            toast.error(res?.message);
        }
    }


    // get Department Data
    const getDepartmentData = async () => {
        const res = await HttpClient.requestData("view-department", "GET", {});
        let apiData = []
        if (res && res?.status) {
            apiData = res?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        }
        setDepartmentDropdown(apiData);
    }


    // get User Data department-wise
    const getUserData = async () => {
        let data = {
            departmentArray: departmentId
        }
        const res = await HttpClient.requestData("fetch-user-department-wise", "POST", data);
        let apiData = []
        if (res && res?.status) {
            apiData = res?.data?.map((item) => ({
                label: item?.userName,
                value: item?._id,

            }));
            apiData?.unshift({ label: "All", value: "all" });
        }
        setUserDropdown(apiData);
    }


    // onchange of department data 
    const handleSelectDepartment = e => {
        setDepartmentId(e ? e?.map(option => option.value) : []);
    }

    // onchange of User data
    const handleSelectUser = e => {
        const values = e ? e.map(option => option.value) : [];
        if (values.includes("all")) {
            setAssignedUsers(UserDropdown?.filter(option => option.value !== "all")?.map(option => option.value));
        } else {
            setAssignedUsers(values);
        }
    }

    //Submit data
    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        // console.log("formValue",formValue);
        e.preventDefault();

        if (validate()) {
            return
        }

        const data = {
            "initiativeTypeID": formValue.initiativeTypeID,
            "nameOfInitaitive": formValue.nameOfInitaitive,
            "description": formValue.description,
            "startDate": moment(formValue.startDate).format("YYYY-MM-DD"),
            "endDate": moment(formValue.endDate).format("YYYY-MM-DD"),
            "rewardPoints": +formValue.rewardPoints,
            "initiativeView": formValue?.initiativeView,
            "departmentId": departmentId,
            "assignedUsers": assignedUsers

        }

        // console.log("datapoiu", data);
        // return false;
        setIsLoading(true);
        const res = await HttpClient.requestData("add-initiative", "POST", data);
        console.log("datapoiu", res)
        if (res && res?.status) {
            toast.success("Data Added Successfully");
            setFormValue(initValue);

            // navigate('/manage-category');
            setIsLoading(false);
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getInitiativeTypeData();
        getDepartmentData();
    }, [])

    useEffect(() => {
        if (departmentId.length > 0) {
            getUserData();
        }
    }, [departmentId]);

    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Projects
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="formGroupExampleInput">Select Projects Type</label>
                                    <select
                                        class="form-control"
                                        aria-label="Default select example"
                                        name="initiativeTypeID"
                                        value={formValue.initiativeTypeID}
                                        onChange={
                                            handleChange
                                        }
                                    >
                                        <option value={""} disabled>Select Category</option>
                                        {initiativeType?.map((item, i) =>
                                            <option key={i} value={item?._id} onClick={() => setmaxreward(item?.rewardPoints)}>{item?.initiativetype}</option>
                                        )
                                        }
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formGroupExampleInput">Name Of Projects</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name Of Projects"
                                        name="nameOfInitaitive"
                                        value={formValue.nameOfInitaitive}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-12">
                                    {/* <div className="col-6"> */}
                                    <label htmlFor="formGroupExampleInput">Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Description of Projects."
                                        name="description"
                                        value={formValue.description}
                                        onChange={handleChange}
                                        rows="5"
                                        cols="5"
                                    >{formValue.description}</textarea>
                                    {/* </div> */}


                                </div>




                                {/* <div className="col-6 mt-2">
                                    <label for="number">Department list</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div>
                                        <Select
                                            options={deptlist?.map(ele => ({ value: ele?._id, label: ele?.departmentName }))}
                                            defaultValue={selectedOption}
                                            class="form-control col-6"
                                            onChange={(selectedOptions) => {
                                                const deptIds = selectedOptions.map(option => option.value);
                                                setSelectedOption(selectedOptions);
                                                console.log(selectedOptions);
                                                setFormValue({ ...formValue, departmentId: deptIds });
                                            }}
                                            // value={dep?.map(ele => ({ value: ele?._id, label: ele?.departmentName }))}
                                            isMulti
                                            placeholder={"select department"}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 mt-2">
                                    <label for="number">Targeted Audiance</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <div>
                                        <Select
                                            options={deptlist?.map(ele => ({ value: ele?._id, label: ele?.departmentName }))}
                                            defaultValue={selectedOption}
                                            class="form-control col-6"
                                            onChange={(selectedOptions) => {
                                                const deptIds = selectedOptions.map(option => option.value);
                                                setSelectedOption(selectedOptions);
                                                console.log(selectedOptions);
                                                setFormValue({ ...formValue, departmentId: deptIds });
                                            }}
                                            // value={dep?.map(ele => ({ value: ele?._id, label: ele?.departmentName }))}
                                            isMulti
                                            placeholder={"select department"}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div> */}

                                <div className="col-12">
                                    <p className="rsntxrpnewtxt">Select One</p>
                                    <div className="isHighLightedRadiodivnewdiv">
                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                id=""
                                                name="initiativeView"
                                                value="department"
                                                checked={formValue?.initiativeView === "department"}
                                                onChange={handleChange}

                                            />
                                            <label style={{ color: "black" }}>Department</label>
                                        </div>


                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                id=""
                                                name="initiativeView"
                                                value="all"
                                                checked={formValue?.initiativeView === "all"}
                                                onChange={handleChange}

                                            />
                                            <label style={{ color: "black" }}>All</label>
                                        </div>



                                    </div>
                                </div>


                                {
                                    formValue?.initiativeView === "department"
                                        ?
                                        (
                                            <>
                                                <div className="col-12">
                                                    <p className="rsntxrpnewtxt">Department</p>

                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        className="typstrningwhselctnew"
                                                        components={animatedComponents}
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={DepartmentDropdown}
                                                        onChange={handleSelectDepartment}
                                                        value={DepartmentDropdown?.filter(option => departmentId?.includes(option.value))}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <p className="rsntxrpnewtxt">Select People</p>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        className="typstrningwhselctnew"
                                                        components={animatedComponents}
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={UserDropdown}
                                                        onChange={handleSelectUser}
                                                        value={UserDropdown?.filter(option => assignedUsers?.includes(option.value))}
                                                    />
                                                </div>

                                            </>
                                        )
                                        :
                                        (
                                            <></>
                                        )
                                }

                                <div className="col-6 mt-2">
                                    <label htmlFor="formGroupExampleInput">Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="startDate"
                                        value={formValue.startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-6 mt-2">
                                    <label htmlFor="formGroupExampleInput">End Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="endDate"
                                        value={formValue.endDate}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="formGroupExampleInput">Reward Points</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        // placeholder="Add Reward Point (e.g. Number : '10'/'20' etc.)"
                                        placeholder={`Reward Points should be less than or equal to ${rewardP}`}
                                        name="rewardPoints"
                                        value={formValue.rewardPoints}
                                        onChange={handleChange}
                                    />
                                </div>


                            </div>







                            <div class="col-12 d-flex justify-content-end ">

                                <button
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                    class="btn btn-primaryb mt-3"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    Add Projects
                                </button>

                            </div>


                        </form>
                    </section>
                </div>
            </div >



        </div >
    )
}

export default AddInitiativeData