import React, { useEffect, useState } from "react";
import "./Authentication1.css";
import HttpClient from "../utils/HttpClient";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import logimg from "../Images/LogNewPic.png"
import logogo from "../Images/logcompany.png"
// import btmlogo from "../Images/bottomlogo.png"
import Hide from "../Images/Hide.png"
// import Show from "../Images/headericon/View.png"



const Loginn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passord, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);

  const handleSubmit = async (e) => {
    // console.log("valuesdd");
    e.preventDefault();

    if (!email) return toast.error("Email is Required");
    if (!passord) return toast.error("Email is Required");

    const data = {
      email: email,
      password: passord,
    };
    const res = await HttpClient.requestData("login", "POST", data);
    console.log("resCat", res);
    if (res && res.status) {
      navigate("/");
      reactLocalStorage.setObject("userDataSos", { token: res?.data?.token });
      toast.success("Logged In Successfully");
      setEmail("");
      setPassword("");
    } else {
      toast.error(res.message);
    }
  };

  //pasword toggle
  const passfalse = () => {
    setTogglePassword(true);
  };
  const passtrue = () => {
    setTogglePassword(false);
  };
  const handleForgetPass = () => {
    navigate('/forgotPass')
  }
  return (
    <div className="container-parent">

      {/* <input type="checkbox" id="flip" /> */}
      {/* <div className="cover">
          <div className="front">
            <img src={frontImage} alt="" />
            <div className="text">
              <span className="text-1">
                Every new friend is a <br /> new adventure
              </span>
              <span className="text-2">Let's get connected</span>
            </div>
          </div>
        </div> */}

      {/*Udayan start**/}
      {/* <div className="lgnadivld">
        <div className="loginalaimg">
          <img src={logipageimage} alt="..." />
          <div className="divfrmabs">
            <div className="forms">
              <div className="form-content">
                <div className="login-form">
                  <div className="title">
                    <h4>WELCOME</h4>
                    <div className="companynameimgwh">
                      <img src={companyname} alt="/" />
                    </div>
                    <p>Login To Stay Connected</p>
                  </div>
                  <form action="#" onSubmit={handleSubmit}>
                    <div className="input-boxes">
                      <div className="input-box">

                        <i className="fa-regular fa-user"></i>
                        <input
                          name="email"
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          placeholder="User ID"
                          required
                        />
                      </div>
                      <div className="input-box inputboxkey">

                        <i className="fa-solid fa-key"></i>
                        <input
                          type="password"
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          placeholder="Password"
                          required
                        />
                      </div>

                      <div className="rmdirfgdiv">
                        <div className="checkdivbox">
                          <input type="checkbox" id="" name="" value="" />
                          <label for=""> Remember Me</label>
                        </div>
                        <div className="text">
                          <a href="#">Forgot password?</a>
                        </div>

                      </div>
                      <div className="button input-box">
                        <input type="submit" value="Submit" />
                      </div>
                      <div className="dljkflsxsosgn">
                        <div className="">
                          <p className="sccghdnttxt">Don't have an Account Please</p>
                        </div>
                        <div className="">
                          <Link to="/" className="sgnuptct">Sign Up</Link>
                        </div>
                      </div>
                      <div className="exptfflx">
                        <div className="ecxpkkhubimg">
                          <img src={employeeexperiencehub} alt="..." />
                        </div>
                        <div className="">
                          <p className="eperitxt">Elite Employee Experience Hub</p>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*Udayan end**/}



      {/*shiltu start**/}
      <div className="loginsection_wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="loginimg_wrap">
              <img src={logimg} className="img-fluid" alt="image" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="logcontent_wrap">

              <div className="responsivelogogo_design">
                <div className="logogo_icon">
                  <img src={logogo} className="img-fluid" alt="..." />
                </div>
              </div>

              <div className="rightcontentbox">
                <h4 className="heading">Log in to your account</h4>

                <form
                  action="#"
                  onSubmit={handleSubmit}
                >


                  <div className="form-group">
                    <label htmlFor="InputEmail1">Email Id</label>
                    <input
                      name="email"
                      // onBlur={handleBlur}
                      // value={values?.email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="InputEmail1"
                      aria-describedby="emailHelp"
                      required
                      placeholder="Email here..."
                    // id="exampleEmail"
                    />

                  </div>

                  {/* <div className="form-group"> */}
                  <div className="form-group">
                    <label htmlFor="Inputpassword">Password</label>
                    <div className="inputcompanydtls">
                      <input
                        // type="password"
                        type={togglePassword ? "text" : "password"}
                        // onBlur={handleBlur}
                        onChange={(e) => setPassword(e.target.value)}
                        value={passord}
                        name="password"
                        className="form-control"
                        id="Inputpassword"
                        placeholder="Password here..."
                        required
                      //  id="examplePassword"
                      />

                      <div className='Hideimgdivicn'
                      //  onClick={togglePasswordVisibility}
                      >

                        {/* toggle password */}
                        {/* <div className="eye_icon">
                                                    {togglePassword ? (
                                                        <i
                                                            className="fa-regular fa-eye"
                                                            onClick={() => passtrue()}
                                                        ></i>
                                                    ) : (
                                                        <i
                                                            class="fa-regular fa-eye-slash"
                                                            onClick={() => passfalse()}
                                                        ></i>
                                                    )}
                                                </div> */}


                        {/* <img src={Hide}

                                                    alt="..." /> */}
                      </div>


                    </div>
                    {/* <div className="icon" type="button" style={{ color: 'black' }}>
                      {showHidebtnText ? (
                        <AiFillEyeInvisible
                          onClick={() => {
                            setShowHideBtnText(false), toggleInput();
                          }}
                        />
                      ) : (
                        <AiFillEye
                          onClick={() => {
                            setShowHideBtnText(true), toggleInput();
                          }}
                        />
                      )}
                    </div> */}
                  </div>

                  {/* <div className="checkbox_area">

                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="termsandcondition"
                                                checked={agreeTerms}
                                                onChange={() => setAgreeTerms(!agreeTerms)}
                                            />
                                            <label htmlFor="termsandcondition">I agree with the <Link>terms and conditions</Link></label>
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="rememberPasswoard"
                                                checked={rememberPassword}
                                                onChange={() => setRememberPassword(!rememberPassword)}
                                            />
                                            <label htmlFor="rememberPasswoard">Remember Password</label>
                                        </div>

                                    </div> */}

                  <div className="submitarea_wrap">
                    <button to="/" className="login_btn" type="submit"
                    // onClick={handleFormSubmit} 
                    > Login </button>
                    <button type="button"
                      onClick={handleForgetPass}
                      className="forgot_btn">Forgot Password? *</button>
                  </div>

                </form>

                <div className="advicetext">
                  <p>* still can't log in? <br /> <span>Contact Your Company HP.</span></p>
                </div>

                {/* <div className="bottomlogo">
                                    <div className="imgwrap">
                                        <img src={btmlogo} className="img-fluid" alt=".." />
                                    </div>
                                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>

      {/*shiltu end**/}


    </div >
  );
};

export default Loginn;