

import React, { useEffect, useState } from 'react'
import CustomLoader from '../../../CustomComponents/loader/CustomLoader';
import toast from 'react-hot-toast';
import HttpClient from '../../../utils/HttpClient';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { FaStarOfLife } from 'react-icons/fa';
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "15px",
    fontWeight: "bold"

}

const AddHr = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [DesignationDropdown, setDesignationDropdown] = useState([]);
    const [DesignartionName, setDesignationName] = useState("");
    const [DepartmentName, setDepartmentName] = useState("");
    //const [EmpData, setEmpData] = useState([]);
    const initValue = {
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        password: "",
        phoneNo: "",
        gender: "",
        education: "",
        dob: "",
        image: "",
        employeeid: "",
        address:""
    }
    const [formValue, setFormValue] = useState(initValue);
    const [options, setOptions] = useState([]); // Options for the dropdown
    const [selectedIds, setSelectedIds] = useState([]); // Selected IDs
    const animatedComponents = makeAnimated();
    // console.log(selectedIds, "selectedIds");
    const [hrData, setHrData] = useState([]); // Options for the dropdown
    const [HrId, setHrid] = useState(null); // Selected IDs
    const [uploading, setUploading] = useState(false);
    const [education, setEducation] = useState([]);

    console.log(HrId, hrData, "selectedIds");

    const validate = () => {
        if (!formValue?.firstName) {
            toast.error("firstName is required");
            return true
        }
        if (!formValue?.lastName) {
            toast.error("lastName is required");
            return true
        }

        if (!formValue?.email) {
            toast.error("email is required");
            return true
        }
        if (!formValue?.password) {
            toast.error("password is required");
            return true
        }
        if (!formValue?.phoneNo) {
            toast.error("phoneNo is required");
            return true
        }

        if (!formValue?.gender) {
            toast.error("Gender is required");
            return true
        }
        if (!formValue?.education) {
            toast.error("Education is required");
            return true
        }
        if (!formValue?.dob) {
            toast.error("Date of Birth is required");
            return true
        }
        if (!formValue?.image) {
            toast.error("Image is required");
            return true
        }

        return false
    }

    //Fetch Emp Data
    const getManagerData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("companyAdmin-view-card", "POST", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.managerData?.map((item, i) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item._id,

            }));
        } else {
            setIsLoading(false);
        }
        setOptions(apiData);
    }
    // get HR Data
    const getHrData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("companyAdmin-view-card", "POST", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.hrData?.map((item, i) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item._id,

            }));
        } else {
            setIsLoading(false);
        }
        setHrData(apiData);
    }

    //for view all education
    const ViewAllEducation = async () => {
        const res = await HttpClient.requestData("view-education", "GET", {})

        if (res && res?.status) {
            const formattedDepartments = res?.data?.map((item) => ({
                label: item.name,
                value: item._id,
            }));
            setEducation(formattedDepartments);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue(prev => ({ ...prev, [name]: value }));
    }

    // image change
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);
        const form = new FormData();
        form.append("image", image);

        let res = await HttpClient.fileUplode("image-upload", "POST", form);
        setUploading(false);
        if (res.status) {
            toast.success("Image uploaded successfully");
            setFormValue(prev => ({ ...prev, image: res?.image }))

            // setImageURL(res?.image);
        } else {
            toast.error(res?.message || "Error uploading image");
        }
    };


    // get Designation/Department Data
    const getDepartmentData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-department", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        } else {
            setIsLoading(false);
        }
        setDepartmentDropdown(apiData);
    }
    // get Designation Data
    const getDesignationData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-designation", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                label: item?.name,
                value: item?._id,

            }));
        } else {
            setIsLoading(false);
        }
        setDesignationDropdown(apiData);
    }

    //pick designation name
    const handleSelectDesigName = selectedOption => {
        // Update the selected IDs array
        setDesignationName(selectedOption ? selectedOption.value : "");
    }
    //pick department name
    const handleSelectDepartName = selectedOption => {
        // Update the selected IDs array
        setDepartmentName(selectedOption ? selectedOption.value : "");
    }
    console.log('hello', DepartmentName)

    const handleSelectChange = selectedOption => {
        // Update the selected IDs array
        setSelectedIds(selectedOption ? selectedOption.value : null);
    }
    const handleSelectHrID = selectedOption => {
        // Update the selected IDs array
        setHrid(selectedOption ? selectedOption.value : null);
    }


    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();

        if (validate()) {
            return
        }

        const data = {
            "companyCode": 66853136,
            "firstName": formValue?.firstName,
            "lastName": formValue?.lastName,
            "userType": "Admin",
            "employeeid": formValue?.employeeid,
            "email": formValue?.email,
            "password": formValue?.password,
            "address": formValue?.address,
            "contact": parseInt(formValue?.phoneNo),
            "gender": formValue.gender,
            "education": formValue.education,
            "dob": formValue.dob,
            "image": formValue.image,
            "designation": DepartmentName,
            "userDesignation": DesignartionName
        }

        setIsLoading(true);
        const res = await HttpClient.requestData("companyAdmin-register-HR", "POST", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Added Successfully");
            setFormValue(initValue);
            // navigate('/manage-category');
            setSelectedIds([])
            setHrid(null)
            setIsLoading(false);
            console.log(data, "apiData")
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getManagerData();
        getHrData();
        getDepartmentData();
        getDesignationData();
        ViewAllEducation();
    }, [])

    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Hr
                            <hr />
                        </div>
                        <form>
                            <div className="row">



                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">First Name<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        value={formValue.firstName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Last Name<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={formValue.lastName}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Employee Id<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="employeeid"
                                        name="employeeid"
                                        autocomplete="off"
                                        value={formValue?.employeeid}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Designation<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Designation"
                                        name="designation"
                                        value={formValue.designation}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Email<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="email"
                                        name="email"
                                        autocomplete="off"
                                        value={formValue?.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Password<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="password"
                                        name="password"
                                        value={formValue?.password}
                                        autocomplete="off"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Contact No<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Contact Number"
                                        name="phoneNo"
                                        value={formValue?.phoneNo}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Address<span className="text-danger">&nbsp;*</span></label>
                                    <textarea

                                        className="form-control"
                                        placeholder="Enter your address here(e.g. '123 Main Street, Cityville, State, ZIP Code')"
                                        name="address"
                                        value={formValue?.address}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Search/Select Department Name<span className="text-danger">&nbsp;*</span></label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={DepartmentDropdown}
                                        onChange={handleSelectDepartName}
                                        value={DepartmentDropdown.find(option => option.value === DepartmentName)}
                                    />
                                </div>

                                {/* designation */}
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Search/Select Designation Name<span className="text-danger">&nbsp;*</span></label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={DesignationDropdown}
                                        onChange={handleSelectDesigName}
                                        value={DesignationDropdown.find(option => option.value === DesignartionName)}
                                    />
                                </div>

                                <div className="col-6 mt-2">
                                    <label htmlFor="formGroupExampleInput">
                                        Select Gender<span className="text-danger">&nbsp;*</span>
                                    </label>
                                    <select
                                        class="form-control mb-4"
                                        aria-label="Default select example"
                                        name="gender"
                                        value={formValue.gender}
                                        onChange={handleChange}
                                    >
                                        <option value={""} disabled>Selecet Gender</option>
                                        <option value={"male"}>Male</option>
                                        <option value={"female"}>Female</option>
                                        {/* <option value={"other"}>Other</option> */}
                                    </select>

                                </div>

                                {/* 'HS','BACHELORS','MASTERS'] */}
                                <div className="col-6 mt-2 ">
                                    <label htmlFor="formGroupExampleInput">
                                        Select Education<span className="text-danger">&nbsp;*</span>
                                    </label>
                                    {/* <select
                                        class="form-control mb-4"
                                        aria-label="Default select example"
                                        name="education"
                                        value={formValue.education}
                                        onChange={handleChange}
                                    >
                                        <option value={""} disabled>Selecet Education</option>
                                        <option value={"HS"}>HS</option>
                                        <option value={"BACHELORS"}>BACHELORS</option>
                                        <option value={"MASTERS"}>MASTERS</option>
                                    </select> */}
                                    <Select
                                        className="select"
                                        options={education}
                                        value={education?.find((d) => d.value === formValue.education)}
                                        onChange={(selectedOption) => {
                                            setFormValue({
                                                ...formValue,
                                                education: selectedOption?.value || '',
                                            });
                                        }}
                                    />
                                </div>

                                <div className="col-md-6 ">
                                    <label htmlFor="formGroupExampleInput">
                                        Date of Birth<span className="text-danger">&nbsp;*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Contact Number"
                                        name="dob"
                                        value={formValue?.dob}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="formGroupExampleInput">
                                        Image<span className="text-danger">&nbsp;*</span>
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        // value={image}
                                        accept="image/*"
                                        name="image"
                                        onChange={handleImageChange}
                                    />

                                    {
                                        uploading &&
                                        <p>image uploading......</p>
                                    }
                                    {
                                        formValue?.image !== "" &&
                                        <div className="imageuplddiv">
                                            <img src={formValue?.image} alt="" />
                                            <div
                                                className="imageupldcrs"
                                                onClick={() => {
                                                    setFormValue(prev => ({ ...prev, image: "" }))
                                                }}
                                            >
                                                <i class="fa-solid fa-xmark"></i>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>





                            {/* Button */}
                            <div class="col-12 d-flex justify-content-end">
                                {
                                    !isEdit
                                        ?
                                        <button
                                            type="submit"
                                            onClick={(e) => handleSubmit(e)}
                                            class="btn btn-primaryb mt-3"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Add Data
                                        </button>
                                        :
                                        <button
                                            type="submit"
                                            // onClick={(e) => handleEditSubmit(e)}
                                            class="btn btn-primaryb mt-3"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update Checklist
                                        </button>
                                }
                            </div>
                        </form>

                    </section>
                </div>
            </div >




        </div >
    )
}

export default AddHr