import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import DataTable from 'react-data-table-component';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import { ImCross } from 'react-icons/im';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height

        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}

const SalaryComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [AllData, setAllData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editid, seteditid] = useState();
    const initValue = {
        name: "",
        code: ""
    }
    const [formvalue, setformvalue] = useState(initValue);
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "10%"

        },
        {
            name: 'Code',
            selector: row => row.code,
            width: "35%"
        },

        {
            name: 'Type Name',
            selector: row => row.name,
            width: "35%"
        },

        {
            name: 'Action',
            selector: row => row.action,

        }
    ];

    //for getting all salary type
    const getsalarytypedata = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-salary-strcture-name", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                name: item?.name,
                code: item?.code,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    }

    //for submit functionality of salary type
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formvalue?.name) {
            toast.error('Input blank')
            return;
        }

        const data = {
            name: formvalue?.name,
            code: formvalue?.code
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("add-salary-strcture-name", "POST", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Added Successfully");
            setformvalue(initValue);
            setIsLoading(false);
            getsalarytypedata();


        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    //for delete functionality of salary type
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-salary-strcture-name/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getsalarytypedata();

                toast.success("Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }


    //Edit
    const handleEdit = async (item) => {
        console.log(item, "updatedType")
        setIsEdit(true);
        seteditid(item?._id);
        setformvalue({
            name: item?.name,
            code: item?.code
        })
    }

    //for cancel edit
    const cancelEdit = (e) => {
        setIsEdit(false)
        setformvalue(initValue);
    }

    // edit submit
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        // if (!selectedValue || !userTypeValue) {
        //     toast.error('Input blank')
        //     return;
        // }

        const data = {
            name: formvalue?.name,
            code: formvalue?.code
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-salary-strcture-name/" + editid, "PUT", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setIsEdit(false)
            setformvalue(initValue)
            setIsLoading(false);
            getsalarytypedata();


        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    //change state values
    const handleChange = (e) => {
        const { name, value } = e.target
        setformvalue(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        getsalarytypedata();
    }, [])

    return (
        <div>
            <CustomLoader loading={isLoading} />
            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Salary type
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-6 ">
                                    <label htmlFor="formGroupExampleInput">Code:
                                        <span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Code"
                                        name="code"
                                        onChange={handleChange}
                                        value={formvalue?.code}
                                    />
                                </div>
                                <div className="col-6 ">
                                    <label htmlFor="formGroupExampleInput">Salary Component:
                                        <span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Salary  Name"
                                        name="name"
                                        onChange={handleChange}
                                        value={formvalue?.name}
                                    />
                                </div>
                                {/* <div className="col-6">
                                    <label htmlFor="formGroupExampleInput">Day for Manager<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Add  days (e.g. Number : '10'/'20' etc.)"
                                        name="leaveForManager"
                                        onChange={handleChange}
                                        value={formvalue?.leaveForManager}

                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="formGroupExampleInput">Day for employee<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Add  days (e.g. Number : '10'/'20' etc.)"
                                        name="leaveForEmployee"
                                        onChange={handleChange}
                                        value={formvalue?.leaveForEmployee}

                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="formGroupExampleInput">Day for HR<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Add Days (e.g. Number : '10'/'20' etc.)"
                                        name="leaveForHr"
                                        onChange={handleChange}
                                        value={formvalue?.leaveForHr}

                                    />
                                </div> */}
                            </div>


                            {/* Button */}
                            <div class="col-12 d-flex justify-content-end ">
                                {

                                    !isEdit ? <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Add Salary break down type
                                    </button> :
                                        <div className=' d-flex '>
                                            <button
                                                type="submit"
                                                onClick={(e) => handleEditSubmit(e)}
                                                class="btn btn-primaryb mt-3 btn-grad"
                                            >
                                                Update Salary break down type
                                            </button>
                                            <ImCross
                                                onClick={(e) => cancelEdit(e)}
                                                style={{ fontSize: "20px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
                                        </div>

                                }
                            </div>
                        </form>
                        <form>
                            <div
                                style={headLineStyle}
                                className="page-headline"
                            >
                                View and manage Salary type
                            </div>
                            <div>

                                <DataTable
                                    columns={columns}
                                    data={AllData}
                                    pagination
                                    striped
                                    className=" rounded mt-1"
                                    customStyles={customStyles}
                                />
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    )
}
export default SalaryComponent