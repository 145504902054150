import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import HttpClient from '../../utils/HttpClient';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataTable from 'react-data-table-component';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import toast from 'react-hot-toast';
import { ImCross } from 'react-icons/im';
import { useNavigate } from 'react-router';
const animatedComponents = makeAnimated();

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height

        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "15px",
    fontWeight: "bold"

}

const ManagedBy = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editid, seteditid] = useState(null);
    const [AllData, setAllData] = useState([]);
    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [DesignationDropdown, setDesignationDropdown] = useState([]);
    const [DesignartionName, setDesignationName] = useState(null);
    const [DepartmentName, setDepartmentName] = useState(null);
    const [HrDropdown, setHrDropdown] = useState([]);
    const [HrName, setHrName] = useState(null);


    //pick designation name
    const handleSelectDesigName = selectedOption => {
        setDesignationName(selectedOption ? selectedOption.value : null);
    }


    //pick department name
    const handleSelectDepartName = selectedOption => {
        setDepartmentName(selectedOption ? selectedOption.value : null);
    }


    //pick Hr name
    const handleSelectHrName = selectedOption => {
        setHrName(selectedOption ? selectedOption.value : null);
    }


    // get Designation/Department Data
    const getDepartmentData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-department", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        } else {
            setIsLoading(false);
        }
        setDepartmentDropdown(apiData);
    }


    // get Designation Data
    const getDesignationData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-designation", "GET", {});
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item) => ({
                label: item?.name,
                value: item?._id,

            }));
        } else {
            setIsLoading(false);
        }
        setDesignationDropdown(apiData);
    }


    // get Designation Data
    const getHRData = async () => {
        const data = {
            "departmentId": DepartmentName,
            "designationId": DesignartionName
        }
        const res = await HttpClient.requestData("fetch-hr-desigwise", "POST", data);
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            apiData = res?.data?.map((item) => ({
                label: item?.userName,
                value: item?._id,

            }));
        }
        setHrDropdown(apiData);
    }


    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "10%"

        },
        {
            name: 'Hr Name',
            selector: row => row.hrName,
            width: "25%"
        },

        {
            name: 'Department',
            selector: row => row.department,
            width: "25%"
        },

        {
            name: 'Designation',
            selector: row => row.designation,
            width: "25%"
        },

        {
            name: 'Action',
            selector: row => row.action,

        }
    ];

    //for getting all managed by
    const getmanagedbydata = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-managed-by", "GET", {});
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                hrName: item?.hrName,
                department: item?.department,
                designation: item?.designation,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    }


    //for validation functionality
    const validate = () => {
        if (!DepartmentName) {
            toast.error("DepartmentName is required");
            return true
        }
        if (!DesignartionName) {
            toast.error("Designation is required");
            return true
        }
        if (!HrName) {
            toast.error("HrName is required");
            return true;
        }

        return false
    }


    //for submit functionality
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            return
        }

        const data = {
            "departmentId": DepartmentName,
            "designationId": DesignartionName,
            "hrId": HrName
        }

        setIsLoading(true);
        const res = await HttpClient.requestData("add-managed-by", "POST", data);
        if (res && res?.status) {
            toast.success("Added Successfully");
            setIsLoading(false);
            setDesignationName(null);
            setDepartmentName(null);
            setHrName(null);
            setDesignationDropdown([]);
            setDepartmentDropdown([]);
            setHrDropdown([]);
            getmanagedbydata();
            navigate('/managedby')
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    };


    //for handle delete
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-managed-by/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getmanagedbydata();
                toast.success("Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }


    //Edit
    const handleEdit = async (item) => {
        setIsEdit(true);
        seteditid(item?._id);
        setDesignationName(item?.designationId);
        setDepartmentName(item?.departmentId);
        setHrName(item?.hrId)
    }


    // edit submit
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        if (!DepartmentName) {
            toast.error("DepartmentName is required");
            return false;
        }
        if (!DesignartionName) {
            toast.error("Designation is required");
            return false;
        }
        if (!HrName) {
            toast.error("HrName is required");
            return false;
        }
        
        const data = {
            "departmentId": DepartmentName,
            "designationId": DesignartionName,
            "hrId": HrName
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-managed-by/" + editid, "PUT", data);
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setIsEdit(false)
            setIsLoading(false);
            setDesignationName(null);
            setDepartmentName(null);
            setHrName(null)
            setDesignationDropdown([]);
            setDepartmentDropdown([]);
            setHrDropdown([]);
            getmanagedbydata();

        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    //cancel edit
    const handleisCancelSubmit = (e) => {
        setIsEdit(false);
        setDesignationName(null);
        setDepartmentName(null);
        setHrName(null)
    }


    useEffect(() => {
        getDepartmentData();
        getDesignationData();
        getmanagedbydata();
    }, []);


    useEffect(() => {
        if (DepartmentName && DesignartionName) {
            getHRData();
        }
    }, [DepartmentName, DesignartionName]);

    // console.log("DepartmentName", DepartmentName, DesignartionName)

    return (
        <div>
            <CustomLoader loading={isLoading} />

            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Managedby
                            <hr />
                        </div>
                        <form>
                            <div className="row">

                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Select Department Name<span className="text-danger">&nbsp;*</span></label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={DepartmentDropdown}
                                        onChange={handleSelectDepartName}
                                        value={DepartmentName ? DepartmentDropdown.find(option => option.value === DepartmentName) : null}
                                    />
                                </div>

                                {/* designation */}
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Hr Designation<span className="text-danger">&nbsp;*</span></label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={DesignationDropdown}
                                        onChange={handleSelectDesigName}
                                        value={DesignartionName ? DesignationDropdown.find(option => option.value === DesignartionName) : null}
                                    />
                                </div>


                                {/* Hr name */}
                                <div className="col-md-6 mt-3 ">
                                    <label htmlFor="formGroupExampleInput">Designation-wise Hr Name<span className="text-danger">&nbsp;*</span></label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={HrDropdown}
                                        onChange={handleSelectHrName}
                                        value={DesignartionName && DepartmentName ? HrDropdown.find(option => option.value === HrName) : null}
                                    />
                                </div>

                            </div>



                            {/* Button */}
                            <div class="col-12 d-flex justify-content-end">
                                {
                                    !isEdit
                                        ?
                                        <button
                                            type="submit"
                                            onClick={(e) => handleSubmit(e)}
                                            class="btn btn-primaryb mt-3"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Add Managedby
                                        </button>
                                        :
                                        <>
                                            <button
                                                type="submit"
                                                onClick={(e) => handleEditSubmit(e)}
                                                class="btn btn-primaryb mt-3"
                                                style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                            >
                                                Update Managedby
                                            </button>
                                            <ImCross
                                                style={{ fontSize: "20px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger "
                                                onClick={(e) => handleisCancelSubmit(e)}
                                            />
                                        </>
                                }
                            </div>
                        </form>

                        <form>
                            <div
                                style={headLineStyle}
                                className="page-headline"
                            >
                                View and manage Managedby
                            </div>
                            <div>

                                <DataTable
                                    columns={columns}
                                    data={AllData}
                                    pagination
                                    striped
                                    className=" rounded mt-1"
                                    customStyles={customStyles}
                                />
                            </div>
                        </form>

                    </section>
                </div>
            </div >




        </div >
    )
}

export default ManagedBy