// // DetailsModal.js
// import moment from 'moment';
// import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
// import Modal from 'react-modal';

// Modal.setAppElement('#root'); // Set the root element for accessibility



// const DetailsModal = ({ isOpen, closeModal, details }) => {

//     const [tableData, setTableData] = useState([]);
//     console.log("details", tableData);


//     // fetch Category DAta
//     const getCategoryData = async () => {
//         // setIsLoading(true);


//         setTableData(details);
//     }
//     useEffect(() => {
//         getCategoryData();
//     }, [details]);
//     return (

//         <Modal
//             isOpen={isOpen}
//             onRequestClose={closeModal}
//             contentLabel="Details Modal"
//             className=""
//         >
//             <a href='#' className='mtclose' onClick={closeModal}><i class="fa-solid fa-xmark"></i></a>

//             {
//                 tableData?.map((item, i) => {
//                     return (<>
//                         <div className=' container'>
//                             <div className=' row '>
//                                 <div className='col-12 d-flex justify-content-center '>
//                                     <h2 className='  border-bottom border-danger mb-3'>Job Details</h2>

//                                 </div>
//                                 <hr />
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Job title :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.jobTitle}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Description :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.positionDescription}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Department :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.departmentName}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4' >Interview Process :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.interviewProcess}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Work Mode :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     {item?.period}
//                                     {/* {item?.workMode?.map((item, i) => {
//                                         return (<div>
//                                             <p className='mt-1'>{item},&nbsp;&nbsp;</p>

//                                         </div>)
//                                     })} */}
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Skills required :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.keySkills}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Responsibilities :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.responsibilities}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Qualifications :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.qualification}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Job location :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.jobLocation[0] ? item?.jobLocation[0] : "N/A"}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3 '>
//                                     <h4 className='item-description col-4'>Experience :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.experience_low +"-" +item?.experience_high}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Salary :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.salary}</p>
//                                 </div>
//                                 <div className='col-12 d-flex mt-3'>
//                                     <h4 className='item-description col-4'>Number of openings :</h4>
//                                     &nbsp;&nbsp;&nbsp;
//                                     <p className='mt-1'>{item?.openings}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </>)
//                 })
//             }


//         </Modal >

//     );
// };

// export default DetailsModal;


import React, { useEffect, useState } from 'react'
// import Skills from "../../src/Images/Icons/PNG/Skills.png";
// import Designation from "../../src/Images/Icons/PNG/Designation.png";
// import time from "../../src/Images/Icons/PNG/time.png";
// import LocationIcon from "../../src/Images/Icons/PNG/LocationIcon.png";
// import { useNavigate, useParams } from 'react-router-dom';
import HttpClient from '../../utils/HttpClient';
import Modal from 'react-modal';

Modal.setAppElement('#root');


const DetailsModal = ({ isOpen, closeModal, details }) => {
  const [whyData, setWhyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [tableData, setTableData] = useState([]);


  // fetch Category Data
  const getCategoryData = async () => {
    setTableData(details);
  }
  useEffect(() => {
    getCategoryData();
  }, [details]);


  //for fetching data of why join us
  const getWhyJoinData = async () => {
    const response = await HttpClient.requestData("view-about-company", "GET");
    if (response && response?.status) {
      setWhyData(response?.data);
    }
  };


  //for fetching Department data
  const getDepartmentData = async () => {
    const response = await HttpClient.requestData("view-department", "GET");
    if (response && response.status) {
      setDepartmentData(response?.data);
    } 
  };

  const getDepartmentName = (departmentId) => {
    const department = departmentData.find(department => department._id === departmentId);
    return department ? department.departmentName : '';
  };


  useEffect(() => {
    getWhyJoinData();
    getDepartmentData();
  }, [])



  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Details Modal"
        className=""
      >
        <a href='#' className='mtclose' onClick={closeModal}><i class="fa-solid fa-xmark"></i></a>
        {
          tableData?.map((jobrefferedData) => {
            return (
              <>
                <div className='hiringdivbg'>

                  <div className='bg-brdrdiv'>
                    <div className='bg-grydiv' style={{ borderBottom: "1px solid #000" }}>
                      <h2 className='psttxtppdiv' >{jobrefferedData?.jobTitle}</h2>

                     
                    </div>
                    <div className='dshimgtxt'>
                      
                      {
                        jobrefferedData?.departmentName ? (<div className='bg-grydiv'>
                          <p className='dstgytxy'>{jobrefferedData?.departmentName}</p>
                        </div>) : (<div className='bg-grydiv'>
                          <p className='dstgytxy'>{getDepartmentName(jobrefferedData?.Department)}</p>

                        </div>)
                      }

                    </div>
                    <div className='dshimgtxt'>
                      {/* <div className='imhtnch'>
                      <img src={time} alt="..." />
                    </div> */}
                      <div className='bg-grydiv'>
                        <p className='dstgytxy'>{jobrefferedData?.period}</p>
                      </div>
                    </div>
                    <div className='dshimgtxt'>
                      {/* <div className='imhtnch'>
                      <img src={Skills} alt="..." />
                    </div> */}
                      <div className='bg-grydiv'>
                        <p className='dstgytxy'>
                          {jobrefferedData?.keySkills?.slice(0, -1).join(", ")
                            + (jobrefferedData?.keySkills?.length > 1 ? "," : "")
                            + (jobrefferedData?.keySkills?.length > 1 ? " and " : "")
                            + jobrefferedData?.keySkills?.slice(-1)}
                        </p>
                      </div>
                    </div>
                    <div className='dshimgtxt'>
                      {/* <div className='imhtnch'>
                      <img src={LocationIcon} alt="..." />
                    </div> */}
                      <div className='bg-grydiv'>
                        <p className='dstgytxy'>
                          {jobrefferedData?.jobLocation?.slice(0, -1).join(", ")
                            + (jobrefferedData?.jobLocation?.length > 1 ? "," : "")
                            + (jobrefferedData?.jobLocation?.length > 1 ? " and " : "")
                            + jobrefferedData?.jobLocation?.slice(-1)}
                        </p>
                      </div>
                    </div>

                    <div className='inpuytboxdyflx'>
                      <p className='optxtvn'>Open to Referral Hiring</p>
                      <input type="checkbox"
                        id=""
                        name=""
                        value=""
                        checked={jobrefferedData?.isReffered}
                      />
                    </div>
                  </div>

                  {
                    whyData?.map((item, ind) => {
                      return (
                        <>
                          <div className='jndivtxtwhy' key={ind} >
                            <p className="jntxtdivhrin">Why Join US?</p>
                            <div className='bg-grydiv'>
                              <p className='frcttxy'>
                                {item?.whyJoinUs}
                              </p>
                            </div>
                          </div>
                        </>
                      )
                    })
                  }



                  <div className='jndivtxtwhy'>
                    <p className="jntxtdivhrin">Position Description</p>
                    <div className='bg-grydiv'>
                      <p className='frcttxy'>{jobrefferedData?.positionDescription}</p>
                      {/* <p className='frcttxy'>Fractals Global’s mission is to ‘empower every person and every organization on the
            planet to achieve more’. At studio+91 at the India Development Center (MSIDC), we work
            together across a multitude of disciplines: design, research and design engineering, to
            empower people all over the planet. Our diverse teams are constantly iterating, solving
            problems and collaborating with product managers, engineers to craft meaningful and
            relevant product experiences.</p>
          */}
                    </div>
                  </div>
                  <div className='jndivtxtwhy'>
                    <p className="jntxtdivhrin">Responsibilities:</p>
                    <div className='bg-grydiv'>
                      <ul className='txtillsltall'>
                        <li className='txtlixtli'>
                          {jobrefferedData?.responsibilities}
                        </li>
                        {/* <li className='txtlixtli'>
              You will be leading research projects independently, either alone or with a team
              of researchers, working closely with a team of designers, product managers and
              engineers in a highly collaborative and agile environment.
            </li>
            <li className='txtlixtli'>
              Projects usually start with a fundamental inquiry, arrived collaboratively with
              stakeholders across design product & engineering. This also entails identifying &
              prioritizing research inquiries and defining the research strategy for your product
              area
            </li>
             */}
                      </ul>
                    </div>
                  </div>
                  <div className='jndivtxtwhy'>
                    <p className="jntxtdivhrin">Qualifications:</p>
                    <div className='bg-grydiv'>
                      <ul className='txtillsltall'>
                        <li className='txtlixtli'>
                          {jobrefferedData?.qualification}
                        </li>
                        {/* <li className='txtlixtli'>
              {jobrefferedData?.addQualification}
            </li> */}
                        {/* <li className='txtlixtli'>
              You will be leading research projects independently, either alone or with a team
              of researchers, working closely with a team of designers, product managers and
              engineers in a highly collaborative and agile environment.
            </li>
            <li className='txtlixtli'>
              Projects usually start with a fundamental inquiry, arrived collaboratively with
              stakeholders across design product & engineering. This also entails identifying &
              prioritizing research inquiries and defining the research strategy for your product
              area
            </li>
           */}
                      </ul>
                    </div>
                  </div>
                  <div className='jndivtxtwhy'>
                    <p className="jntxtdivhrin">Good To Have</p>
                    <div className='bg-grydiv'>
                      <ul className='txtillsltall'>
                        <li className='txtlixtli'>
                          {jobrefferedData?.addQualification}
                        </li>
                        {/* <li className='txtlixtli'>
              You will be leading research projects independently, either alone or with a team
              of researchers, working closely with a team of designers, product managers and
              engineers in a highly collaborative and agile environment.
            </li>
            <li className='txtlixtli'>
              Projects usually start with a fundamental inquiry, arrived collaboratively with
              stakeholders across design product & engineering. This also entails identifying &
              prioritizing research inquiries and defining the research strategy for your product
              area
            </li>
           */}
                      </ul>
                    </div>
                  </div>

                </div>

              </>
            )
          })
        }
      </Modal >

    </>
    //   </div>

    // </div>
  )
}

export default DetailsModal
