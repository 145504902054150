import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import DataTable from 'react-data-table-component';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import { ImCross } from 'react-icons/im';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height

        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "35px",
    fontWeight: "bold"

}

const Expense = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [AllData, setAllData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isEdit1, setIsEdit1] = useState(false);
    const [editid, seteditid] = useState("");
    const [Editid, setEditid] = useState("");
    const [editData, setEditData] = useState({});
    const [EditData, seteditData] = useState({})
    const [categoryData, setCategoryData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [day, setDay] = useState("");

    useEffect(() => {
        // if (checkListData.length !== 0) {
        const apiData = categoryData?.map((item, i) => ({
            id: i + 1,
            sl: i + 1,
            name: item?.name,
            edit: i >= 7 ? <EditDeleteIcon
                onClickEdit={(e) => handleEdit1(item)} 
            /> : null,
            delete:  i >= 7 ?<EditDeleteIcon
                onClickDelete={(e) => handleDelete1(item?._id)}
            />:null,
            status: <div>
            {
                item?.status ?
                    <button onClick={() => handleDeactive1(item?._id)}
                        type="button" className="btn btn-success floating-message"
                        data-toggle="tooltip" data-placement="top" title="Click to change">
                        Active
                    </button> :
                    <button type="button" className="btn btn-danger floating-message"
                        onClick={() => handleActive1(item?._id)}
                        data-toggle="tooltip" data-placement="top" title="Click to change">
                        Inactive
                    </button>
            }
        </div>

        }));
        setTableData(apiData);
        // }
    }, [categoryData, day])

    const initValue = {
        name: ""
    }

    const initValue1 = {
        componentId: "",
        name: ""
    }

    const [formvalue, setformvalue] = useState(initValue);
    const [formValue, setformValue] = useState(initValue1);


    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "10%"

        },
        {
            name: 'Type Name',
            selector: row => row.name,
            width: "35%"
        },

        // {
        //     name: 'Action',
        //     selector: row => row.action,

        // }

        {
            name: 'Edit',
            selector: row => row.edit,

        },

        {
            name: 'Delete',
            selector: row => row.delete,

        },

        {
            name: 'Status',
            selector: row => row.status,

        },

        // {
        //     name: 'Inactive',
        //     selector: row => row.inactive,

        // }
    ];


    const columns1 = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "10%"

        },
        {
            name: 'Type Name',
            selector: row => row.name,
            width: "35%"
        },

        // {
        //     name: 'Action',
        //     selector: row => row.action,

        // }

        {
            name: 'Edit',
            selector: row => row.edit,

        },

        {
            name: 'Delete',
            selector: row => row.delete,

        },
        {
            name: 'Status',
            selector: row => row.status,

        }
    ];

    //for getting all category
    const getsalarydeductiondata = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-expense-component", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.reverse()?.map((item, i) => ({
                _id: item?._id,
                sl: i + 1,
                name: item?.name,
                edit:  i >= 7 ? <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                />:"Not editable",
                delete: i >= 7 ? <EditDeleteIcon
                    onClickDelete={(e) => handleDelete(item?._id)}
                /> :"Not deletable",
                status: <div>
                {
                    item?.status ?
                        <button onClick={() => handleDeactive(item?._id)}
                            type="button" className="btn btn-success floating-message"
                            data-toggle="tooltip" data-placement="top" title="Click to change">
                            Active
                        </button> :
                        <button type="button" className="btn btn-danger floating-message"
                            onClick={() => handleActive(item?._id)}
                            data-toggle="tooltip" data-placement="top" title="Click to change">
                            Inactive
                        </button>
                }
            </div>
                // action: <EditDeleteIcon
                //     onClickEdit={(e) => handleEdit(item)}
                //     onClickDelete={(e) => handleDelete(item?._id)}
                // />
            }));
        } else {
            setIsLoading(false);
        }
        setAllData(apiData);
    }

    //for submit functionality of expense category
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formvalue?.name) {
            toast.error('Input blank')
            return;
        }
        const data = {
            name: formvalue?.name
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("add-expense-component", "POST", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Added Successfully");
            setformvalue(initValue);
            setIsLoading(false);
            getsalarydeductiondata();
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    }


    //for submit functionality of sub-category
    const handleSubmit1 = async (e) => {
        e.preventDefault();

        if (!formValue?.name) {
            toast.error('Input blank')
            return;
        }
        const data = {
            componentId: formValue?.componentId,
            name: formValue?.name
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("add-sub-expense-component", "POST", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Added Successfully");
            setformValue(initValue1);
            setIsLoading(false);
            getsalarydeductiondata();
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    }


    //for delete functionality of expense category
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-expense-component/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getsalarydeductiondata();
                toast?.success("Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }


    //Edit
    const handleEdit = async (item) => {
        console.log(item, "updatedType")
        setIsEdit(true);
        seteditid(item?._id);
        setEditData(item);
        setformvalue({
            name: item?.name,

        })
    }


    //for delete functionality of expense sub-category
    const handleDelete1 = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-sub-expense-component/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getsalarydeductiondata();
                getCategoryData();
                toast?.success("Deleted Successfully");
            } else {
                setIsLoading(false);
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }


    //Edit
    const handleEdit1 = async (item) => {
        console.log(item, "updatedType")
        setIsEdit1(true);
        setEditid(item?._id);
        seteditData(item);
        setformValue({
            name: item?.name,
            componentId: item?.componentId
        })
    }

    //for cancel edit
    const cancelEdit = (e) => {
        setIsEdit(false)
        setformvalue(initValue);
    }

    //for cancel sub-category edit
    const cancelEdit1 = (e) => {
        setIsEdit1(false)
        setformValue(initValue1);
    }

    // edit submit
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        // if (!selectedValue || !userTypeValue) {
        //     toast.error('Input blank')
        //     return;
        // }

        const data = {
            name: formvalue?.name
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-expense-component/" + editid, "PUT", data);
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setIsEdit(false)
            setformvalue(initValue)
            setIsLoading(false);
            getsalarydeductiondata();
            getCategoryData(editData?._id)
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }

    // edit sub-category
    const handleEditSubmit1 = async (e) => {
        e.preventDefault();

        // if (!selectedValue || !userTypeValue) {
        //     toast.error('Input blank')
        //     return;
        // }

        const data = {
            // componentId: formValue?.componentId,
            name: formValue?.name
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-sub-expense-component/" + Editid, "PUT", data);
        if (res && res?.status) {
            toast.success("Updated Successfully");
            setIsEdit1(false)
            setformValue(initValue1)
            setIsLoading(false);
            getCategoryData(EditData?._id)
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);

        }
    }


    const getCategoryData = async (id) => {
        // const del = async () => {
        // setIsLoading(true);
        const res = await HttpClient.requestData("view-sub-expense-component/" + id, "GET", {})
        console.log("id", id, res)
        if (res?.status) {
            setIsLoading(false);
            setCategoryData(res?.data)
            // toast?.success("Deleted Successfully");
        }
        // }

    }


    const handleDeactive = async (id) => {
        setIsLoading(true);
        let data = {
            status: "false"
        }
        const res = await HttpClient.requestData(`update-expense-component/${id}`, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("Deactivated Successfully");
            getsalarydeductiondata();
        } else {
            setIsLoading(false);
        }

    }

    const handleActive = async (id) => {
        setIsLoading(true);
        let data = {
            status: "true"
        }

        const res = await HttpClient.requestData(`update-expense-component/${id}`, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("Activated Successfully");
            getsalarydeductiondata();
        } else {
            setIsLoading(false);
        }
    }


    const handleDeactive1 = async (id) => {
        setIsLoading(true);
        let data = {
            status: "false"
        }
        const res = await HttpClient.requestData(`update-sub-expense-component/${id}`, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("Deactivated Successfully");
            getCategoryData(editData?._id);
        } else {
            setIsLoading(false);
        }
    }

    const handleActive1 = async (id) => {
        setIsLoading(true);
        let data = {
            status: "true"
        }
        const res = await HttpClient.requestData(`update-sub-expense-component/${id}`, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("Activated Successfully");
            getCategoryData(editData?._id);
        } else {
            setIsLoading(false);
        }
    }

    //change state values
    const handleChange = (e) => {
        const { name, value } = e.target
        setformvalue(prev => ({ ...prev, [name]: value }));
    };

    // sub-category inputs change
    const handleChange1 = (e) => {
        const { name, value } = e.target
        setformValue(prev => ({ ...prev, [name]: value }));
    }

    const handleSubChange = (e) => {
        // console.log("eoooooo", e.target.value)
        setDay(e.target.value);
        getCategoryData(e.target.value)
    }

    useEffect(() => {
        getsalarydeductiondata();
    }, [])

    return (
        <div>
            <CustomLoader loading={isLoading} />

            {/* Category Section */}
            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                           Expenses Category and Subcategory Components
                        </div>
                        <form>
                            <div className="row  align-items-end justify-content-center">
                                <div className="col-6">
                                    <label htmlFor="formGroupExampleInput">Add Expense Category:
                                        <span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="name"
                                        onChange={handleChange}
                                        value={formvalue?.name}
                                    />
                                </div>

                           


                            {/* Button */}
                            <div class="col-6">
                                {

                                    !isEdit ? <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        class="btn btn-primaryb"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Add Expense Category
                                    </button> :
                                        <div className=' d-flex '>
                                            <button
                                                type="submit"
                                                onClick={(e) => handleEditSubmit(e)}
                                                class="btn btn-primaryb"
                                            >
                                                Update Expense Category
                                            </button>
                                            <ImCross
                                                onClick={(e) => cancelEdit(e)}
                                                style={{ fontSize: "20px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
                                        </div>

                                }
                            </div>

                            </div>
                        </form>
                        <form>
                            <div
                                style={headLineStyle}
                                className="page-headline"
                            >
                                View and manage Expense category
                            </div>
                            <div>

                                <DataTable
                                    columns={columns}
                                    data={AllData}
                                    pagination
                                    striped
                                    className=" rounded mt-1"
                                    customStyles={customStyles}
                                />
                            </div>
                        </form>


                    </section>
                </div>
            </div>


            {/* Sub-Category Section */}
            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Expenses Sub-category
                        </div>
                        <form>
                            <div className="row">

                                <div className="col">
                                    <label htmlFor="formGroupExampleInput"
                                        className={isEdit ? "edit-tag" : ""}>Select Expenses Sub-Category
                                        <span className=
                                            "text-danger">*</span></label>
                                    <select
                                        class="form-control"
                                        aria-label="Default select example"
                                        name="componentId"
                                        value={formValue.componentId}
                                        onChange={handleChange1}
                                    >
                                        <option value={""} disabled>Select Category</option>
                                        {AllData?.map((item, i) =>
                                            <option key={i} value={item?._id}>{item?.name}</option>
                                        )
                                        }
                                    </select>
                                </div>

                                <div className="col">
                                    <label htmlFor="formGroupExampleInput"
                                        className={isEdit ? "edit-tag" : ""}>Sub-Category
                                        <span className=
                                            "text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sub-category"
                                        name="name"
                                        value={formValue.name}
                                        onChange={handleChange1}
                                    />
                                </div>
                            </div>

                            {/* Button */}
                            <div class="col-12 d-flex justify-content-end ">
                                {
                                    !isEdit1
                                        ?
                                        <button
                                            type="submit"
                                            onClick={(e) => handleSubmit1(e)}
                                            class="btn btn-primaryb mt-3"
                                            style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Add Data
                                        </button>
                                        :
                                        <div className=' d-flex '>
                                            <button
                                                type="submit"
                                                onClick={(e) => handleEditSubmit1(e)}
                                                class="btn btn-primaryb mt-3 btn-grad"
                                            //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                            >
                                                Update Expences Sub-category
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={(e) => cancelEdit1(e)}
                                                className="btn btn-danger mt-3 btn-grad-cancel px-2 mx-4"
                                            //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                            > Cancel
                                            </button>
                                        </div>
                                }
                            </div>
                        </form>

                    </section>
                </div>
            </div >


            <div className="d-flex justify-content-end">
                <div className="form-header">
                    <div className="three mb-1">
                        <h5>Select one to View and manage expenses components</h5>
                    </div>
                    <section className="piechartsBox_area">
                        <form>
                            <div className="row" style={{ marginBottom: "1px" }}>
                                <div className="col">
                                    <label htmlFor="formGroupExampleInput">Select Category</label>

                                    <select
                                        class="form-control"
                                        aria-label="Default select example"
                                        name="catID"
                                        value={day}
                                        onChange={handleSubChange}
                                    >
                                        <option value={""} disabled>Select Category</option>
                                        {AllData?.map((item, i) =>
                                            <option key={i} value={item?._id}>{item?.name}</option>
                                        )
                                        }
                                    </select>
                                </div>
                            </div>
                            <DataTable
                                columns={columns1}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </form>
                    </section>
                </div>

            </div>
        </div>
    )
}
export default Expense