import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import toast from 'react-hot-toast';
import DataTable from 'react-data-table-component';
import CustomLoader from '../../CustomComponents/loader/CustomLoader';
import HttpClient from '../../utils/HttpClient';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import Select from 'react-select';
import { ImCross } from 'react-icons/im'

const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "11px",
    fontWeight: "bold"

}

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const ManageEvent = () => {
    const initValue = {
        Eventname: "",
        Date: "",
        Image: "",
        Eventtype: "",
        notes: "",
        addEmploee: [],
        time: "",
        posttype: "",
        ishighlighted: "",
        affinityGroupId: ""
    }

    const [data, setdata] = useState(initValue);
    const [affData, setaffdata] = useState([]);
    const [userData, setuserdata] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [employeeslist, setemployeelist] = useState([]);
    const [employeeoption, setemployeeop] = useState();
    const [image, setImage] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState({})
    const [imageFile, setimageFile] = useState("");

    const [AllData, setAllData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    //cancel edit
    const handleisCancelSubmit = (e) => {
        setIsEdit(false)
        setdata(initValue)
        // setimageFile("")
        setdata(initValue)
    }
    const handleImageChange = async (e) => {
        // console.log("e",e.target.files);
        setimageFile(URL.createObjectURL(e.target.files[0]))
        let formData = new FormData()

        formData.append("image", e.target.files[0])
        // setIsLoading(true);
        const res = await HttpClient.fileUplode("image-upload", "POST", formData);
        // console.log("image", res)
        // let img = "https://elites3bkt.s3.ap-south-1.amazonaws.com/image/c0cd7570-6e55-11ee-b35b-6f74a2fba80f.png"
        // setFormValue({...formValue,image:img})
        if (res && res?.status) {
            toast.success("Image Uploaded Successfully");
            setdata({ ...data, Image: res.image })
            setIsLoading(false);
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }


    }
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            //width: "3.4rem"
        },
        {
            name: 'Event Date',
            selector: row => row.eventDate,
            //width: "9.6rem"
        },

        {
            name: 'Event Name',
            selector: row => row.eventname,
            //width: "8.9rem"
        },
        {
            name: 'Hosted by',
            selector: row => row.hostedBy,
            // width: "8.9rem"
        },
        {
            name: 'image',
            cell: row => (row.image ? <img alt='no image' src={row.image} style={{ height: "100px", width: "100px", margin: "5px" }} /> : null),
            // width: "8.9rem"
        },
        {
            name: 'Action',
            selector: row => row.action,
        }
    ];
    const getCategoryWiseData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-event", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                eventDate: moment(item?.eventDate).format("DD-MM-YYYY"),
                eventname: item?.eventName ? item?.eventName : '-',
                hostedBy: item?.userType,
                image: item?.image,
                action: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                    onClickDelete={(e) => handleDelete(item?._id)}
                />
            }));
        } else {
            setIsLoading(false);
        }
        const filteredData = apiData?.filter(item =>
            item?.eventname?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
            item?.hostedBy?.toLowerCase()?.includes(searchInput?.toLowerCase())


        );
        setAllData(filteredData);
    }
    //Delete event
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-event/" + id, "DELETE")
            if (res && res?.status) {
                setIsLoading(false);
                getCategoryWiseData();

                toast.success("Event Deleted Successfully");
            } else {
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }
    // click on edit
    const handleEdit = (item) => {
        window.scrollTo(0, 0)
        setIsEdit(true);
        setdata({
            Eventname: item?.eventName,
            Date: item?.eventDate,
            Image: item?.image,
            Eventtype: item?.eventTypeId,
            notes: item?.notes,
            addEmploee: item?.addEmploee,
            time: item?.eventTime,
            posttype: item?.postType,
            ishighlighted: item?.isHighLighted,
            affinityGroupId: item?.affinityGroupId
        })
        setImageURL(item?.image)
        setEditData(item);

    }

    //input change
    const handleChange = (e) => {
        const { name, value } = e.target
        setdata(prev => ({ ...prev, [name]: value }));
    }
    //Edit submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        let Submitdata = {}
        if (data?.posttype === "public") {
            Submitdata = {
                eventName: data?.Eventname,
                eventTypeId: data?.Eventtype,
                eventDate: data?.Date,
                isHighLighted: data?.ishighlighted,
                postType: data?.posttype,
                notes: data?.notes,
                eventTime: data?.time,//24hrs,
                addEmploee: data?.addEmploee,
                image: data?.Image,

            }
        } else {
            Submitdata = {
                eventName: data?.Eventname,
                eventTypeId: data?.Eventtype,
                eventDate: data?.Date,
                isHighLighted: data?.ishighlighted,
                postType: data?.posttype,
                notes: data?.notes,
                eventTime: data?.time,//24hrs,
                affinityGroupId: data?.affinityGroupId,
                image: data?.Image,

            }
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-event/" + editData?._id, "PUT", Submitdata);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Event Data Updated Successfully");

            setimageFile("");

            setIsEdit(false);
            setIsLoading(false);
            getCategoryWiseData();
        } else {
            toast.error(res?.message || "Something Wrong");
            setIsLoading(false);
        }
    };

    // fetch event-type
    const getUserdata = async () => {
        const res2 = await HttpClient.requestData("fetch-event-type", "GET", {});
        console.log('user', res2)
        if (res2 && res2?.status) {
            setuserdata(res2?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
    }
    //fetch affinity data
    const getAffinitydata = async () => {
        const res = await HttpClient.requestData("view-my-affinity-group", "GET", {});
        console.log('user', res)
        if (res && res?.status) {
            setaffdata(res?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
    }
    // fetch employeelist
    const getemployeedata = async () => {
        const res3 = await HttpClient.requestData("view-all-employees", "GET", {});
        console.log('user', res3)
        if (res3 && res3?.status) {
            setemployeelist(res3?.data);
            // const arr2 = res2?.data?.map((obj, j) => ({
            //     useroption: obj._id
            // }));
        }
        setemployeeop(employeeslist?.map(ele => ({ value: ele?._id, label: ele?.userName })))


    }

    useEffect(() => {
        getCategoryWiseData();

    }, [searchInput])
    useEffect(() => {
        getAffinitydata();
        getUserdata();
        getemployeedata();

    }, [])
    return (
        <>
            <div className=''>
                <div className="d-flex justify-content-end">
                    <div className="form-header">

                        <CustomLoader loading={isLoading} />
                        {
                            isEdit ? <>
                                <form class="row g-3 m-2">
                                    {/* Event Name */}
                                    <div class="col-md-6 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
                                            Event Name :
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="EventName"
                                            onChange={handleChange}
                                            value={data?.Eventname}
                                            name="Eventname"
                                        />

                                    </div>
                                    {/*Date*/}
                                    <div class="col-md-6 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                            Date :
                                        </label>

                                        <span style={{ color: "red" }}>*</span>
                                        <div className=' d-flex '>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder=''
                                                // onChange={(e) => handleChange(e, index)}
                                                onChange={handleChange}
                                                value={data?.Date}
                                                name="Date"
                                            />
                                        </div>

                                    </div>
                                    {/* time */}
                                    <div class="col-md-6 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                            Event Time :
                                        </label>

                                        <span style={{ color: "red" }}>*</span>
                                        <div className=' d-flex '>
                                            <input
                                                type="time"
                                                className="form-control"
                                                placeholder=''
                                                // onChange={(e) => handleChange(e)}
                                                onChange={handleChange}
                                                value={data?.time}
                                                name="time"
                                            />
                                        </div>

                                    </div>
                                    {/*Event type  */}
                                    <div class="col-md-6 mt-2">
                                        <label for="number" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>Types of Event :</label>
                                        <span style={{ color: "red" }}>*</span>
                                        <div className=" d-flex ">
                                            <select
                                                class="form-control"
                                                aria-label="Default select example"
                                                name="Eventtype"
                                                value={data.Eventtype}
                                                onChange={handleChange}
                                            >
                                                <option disabled>Select Type</option>
                                                {userData?.map((obj, i) => {
                                                    return (
                                                        <option value={obj?._id} key={i}>{obj?.typeName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* Post type */}
                                    <div class="col-md-6 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                            Share your Event:
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        <div >
                                            <select
                                                class="form-control"
                                                name="posttype"
                                                value={data?.posttype}
                                                onChange={handleChange}
                                            >
                                                <option >Select Type</option>
                                                <option
                                                    value="public"
                                                >Public</option>
                                                <option
                                                    value="private"
                                                >Private</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* employeeslist */}
                                    {data?.posttype === "public" &&
                                        <div class="col-md-6 mt-2">
                                            <label for="number">Employee list</label>
                                            <span style={{ color: "red" }}>*</span>
                                            <div >
                                                {/* <select
                                            class="form-control"
                                            aria-label="Default select example"
                                            multiple={true}
                                            name="addEmploee"
                                            value={data.addEmploee}
                                            onChange={handleChange
                                            }
                                        >
                                            <option disabled>Select Type</option>
                                            {employeeslist?.map((obj, i) => {
                                                return (
                                                    <option value={obj?._id} key={i}>{obj?.firstName} {obj?.lastName}</option>
                                                )
                                            })}
                                        </select> */}
                                                <Select
                                                    options={employeeslist?.map(ele => ({ value: ele?._id, label: ele?.userName }))}
                                                    defaultValue={[]}
                                                    value={employeeoption?.filter(option => data?.addEmploee?.includes(option?.value))}
                                                    class="form-control"
                                                    onChange={(selectedOptions) => {
                                                        const employeeIds = selectedOptions.map(option => option.value);
                                                        setSelectedOption(selectedOptions);
                                                        setdata({ ...data, addEmploee: employeeIds });
                                                    }}
                                                    isMulti
                                                    placeholder={"select employess"}
                                                    closeMenuOnSelect={false}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {/* affinity group */}
                                    {data?.posttype === "private" &&
                                        <div class="col-md-6 mt-2">
                                            <label for="number">Affinity group</label>
                                            <span style={{ color: "red" }}>*</span>
                                            <div >
                                                <select
                                                    class="form-control"

                                                    name="affinityGroupId"
                                                    value={data.affinityGroupId}
                                                    onChange={handleChange}
                                                >
                                                    <option >Select Type</option>
                                                    {affData?.map((obj, i) => {
                                                        return (
                                                            <option value={obj?._id} key={i}>{obj?.groupName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }


                                    {/* ishighligted */}
                                    <div class="col-md-6 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                            IsHighlighted :
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        <div className=' d-flex '>
                                            <div class="form-check mr-2">
                                                <label class="form-check-label">
                                                    <input type="radio"
                                                        class="form-check-input"
                                                        name="ishighlighted"
                                                        onChange={handleChange}
                                                        checked={data?.ishighlighted === 'yes'}
                                                        value="yes" />YES
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input type="radio"
                                                        class="form-check-input"
                                                        name="ishighlighted"
                                                        onChange={handleChange}
                                                        checked={data?.ishighlighted === 'no'}
                                                        value="no" />NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* notes */}
                                    <div class="col-12 mt-2">
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}   >
                                            Event Description :
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        <div className=' d-flex '>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder=''
                                                // onChange={(e) => handleChange(e)}
                                                onChange={handleChange}
                                                value={data.notes}
                                                name="notes"
                                                style={{
                                                    rows: "5",
                                                    cols: "5"
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/*Image*/}
                                    <div class="col-md-6 mt-2 ">
                                        {/* <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}> */}
                                        <label for="inputEmail4" className={`form-label ${isEdit ? 'edit-tag' : ''}`}>
                                            Image
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        <div className=' d-flex '>
                                            <input
                                                type="file"
                                                id="hostedBy"
                                                placeholder="Choose a photo"
                                                // className="form-control"
                                                // value={data?.Image}
                                                onChange={handleImageChange}
                                            // className="upldimagediv"
                                            />
                                        </div>
                                        {imageURL !== "" && (
                                            <div className="upldimgdivbulletin" >
                                                <img
                                                    src={imageURL}
                                                    style={{ height: "100px", width: "100px" }}
                                                    alt="" />
                                                <div className="clsoedivmainrd"
                                                    onClick={() => {
                                                        setImageURL("");
                                                        setImage("");
                                                    }}>
                                                    {/* <i className="fa-solid fa-xmark"></i> */}
                                                    <button type='button'
                                                        className=" mt-1 btn-danger p-1 rounded" style={{ marginLeft: "100px" }}
                                                    >Remove</button>
                                                </div>
                                            </div>
                                        )}

                                        {uploading ? <p>image uploading......</p> : null}

                                    </div>
                                </form>

                                {/* Button */}
                                <div class="col-12 d-flex justify-content-end ">

                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        class="btn btn-primaryb mt-3"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        update Event
                                    </button>
                                    <ImCross onClick={(e) => handleisCancelSubmit(e)} style={{ fontSize: "26px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />

                                </div>
                            </> : ""
                        }
                        <section className="piechartsBox_area">
                            <div
                                style={headLineStyle}
                                className="page-headline"
                            >
                                Event Data
                            </div>
                            <div className=" d-flex justify-content-end mb-2" >
                                <div className="Search_box">
                                    <form className="form-inline">
                                        <input
                                            className="form-control h-75"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                        <div className="Search_icon">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <form >

                                <div className=' container '>

                                    <DataTable

                                        columns={columns}
                                        data={AllData}
                                        pagination
                                        striped
                                        className="rounded your-data-table"
                                        customStyles={customStyles}
                                    />
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageEvent