import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import HttpClient from '../../utils/HttpClient';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataTable from 'react-data-table-component';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import toast from 'react-hot-toast';
import { ImCross } from 'react-icons/im';
// import { useNavigate } from 'react-router';
import moment from 'moment';
import BudgetModal from './BudgetModal';
import EditBudgetModal from './EditBudgetModal';
import Budgetmoremodal from './Budgetmoremodal';
const animatedComponents = makeAnimated();

const customStyles = {
  rows: {
    style: {
      minHeight: '48px', // override the row height

    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      backgroundColor: '#cee0eb', // set the background color for head cells
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

const headLineStyle = {
  textAlign: "center",
  fontSize: "30px",
  color: "#868e96",
  margin: "15px",
  fontWeight: "bold"

}

const Budget = () => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditB, setIsEditB] = useState(false);
  const [editid, seteditid] = useState(null);
  const [AllData, setAllData] = useState([]);
  const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
  const [DesignationDropdown, setDesignationDropdown] = useState([]);
  const [DesignartionName, setDesignationName] = useState(null);
  const [DepartmentName, setDepartmentName] = useState(null);
  const [HrDropdown, setHrDropdown] = useState([]);
  const [HrName, setHrName] = useState("");
  // const [hrEmployeeId, sethrEmployeeId] = useState("");
  const [hrEmployeeIdOptions, setHrEmployeeIdOptions] = useState([]);
  const [addmodal, setaddmodal] = useState(false);
  const [moreamtmodal, setmoreamtmodal] = useState(false);
  const [moreId,setMoreId]=useState("");

  const initValue = {
    budget: 0,
    startOf: "",
    endOf: "",
    // moreAmount: 0
  }

  const [formvalue, setformvalue] = useState(initValue);

  //pick designation name
  // const handleSelectDesigName = selectedOption => {
  //   setDesignationName(selectedOption ? selectedOption.value : null);
  // }


  //pick department name
  // const handleSelectDepartName = selectedOption => {
  //   setDepartmentName(selectedOption ? selectedOption.value : null);
  // }


  //pick Hr name
  const handleSelectHrName = selectedOption => {
    // console.log("selectedOption", selectedOption)
    if (selectedOption) {
      // setHrName(selectedOption.value);
      setHrName([{ label: selectedOption.name, value: selectedOption.value }])
      setHrEmployeeIdOptions(selectedOption.employeeid);
      // setHrEmployeeIdOptions([{ label: selectedOption.employeeid, value: selectedOption.employeeid }]);
      setDepartmentName(selectedOption.designation);
    } else {
      setHrName("");
      setHrEmployeeIdOptions([]);
    }
  }
  // const handleSelectHrName = selectedOption => {
  //   setHrName(selectedOption ? selectedOption.value : null);
  //   sethrEmployeeId(selectedOption?.employeeid)
  // }


  // get Designation/Department Data
  const getDepartmentData = async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("view-department", "GET", {});
    console.log("resGetCat", res)
    let apiData = []
    if (res && res?.status) {
      setIsLoading(false);
      apiData = res?.data?.map((item, i) => ({
        label: item?.departmentName,
        value: item?._id,

      }));
    } else {
      setIsLoading(false);
    }
    setDepartmentDropdown(apiData);
  }


  // get Designation Data
  const getDesignationData = async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("view-designation", "GET", {});
    let apiData = []
    if (res && res?.status) {
      setIsLoading(false);
      apiData = res?.data?.map((item) => ({
        label: item?.name,
        value: item?._id,

      }));
    } else {
      setIsLoading(false);
    }
    setDesignationDropdown(apiData);
  }


  // get Designation Data
  const getHRData = async () => {
    const res = await HttpClient.requestData("view-all-hr", "GET", {});
    console.log("resGetCat", res)
    let apiData = []
    if (res && res?.status) {
      apiData = res?.data?.map((item) => ({
        label: item?.employeeid,
        name: item?.firstName + " " + item?.lastName,
        // label: item?.firstName + " " + item?.lastName,
        value: item?._id,
        employeeid: item?.employeeid,
        designation: item?.designation,
        // moreAmount: item?.moreAmount
      }));
    }
    setHrDropdown(apiData);
  }


  const columns = [
    {
      name: 'SL',
      selector: row => row.sl,
      width: "10%"

    },
    {
      name: 'Hr Name',
      selector: row => row.hrName,
      width: "25%"
    },

    {
      name: 'Department',
      selector: row => row.department,
      width: "25%"
    },

    {
      name: 'HR ID',
      selector: row => row.hrEmployeeId,
      width: "25%"
    },

    {
      name: 'Budget',
      selector: row => row.budget,
      width: "25%"
    },

    {
      name: 'More Amount',
      selector: row => row.moreAmount,
      width: "25%"
    },

    {
      name: 'Total',
      selector: row => row.total,
      width: "25%"
    },

    {
      name: 'Start FY',
      selector: row => row.startOf,
      width: "25%"
    },

    {
      name: 'End FY',
      selector: row => row.endOf,
      width: "25%"
    },

    {
      name: 'Status',
      selector: row => row.status,
      width: "25%"
    },

    {
      name: 'More Amount',
      selector: row => row.more,
    },

    {
      name: 'Action',
      selector: row => row.action,

    }
  ];


  const handleDeactive = async (id) => {
    setIsLoading(true);
    let data = {
      isActive: false
    }
    const res = await HttpClient.requestData(`edit-budget-status/${id}`, "PUT", data);
    if (res && res?.status) {
      setIsLoading(false);
      toast.success("Deactivated Successfully");
      getmanagedbydata();
    } else {
      setIsLoading(false);
    }

  }


  const handleActive = async (id) => {
    setIsLoading(true);
    let data = {
      isActive: true
    }

    const res = await HttpClient.requestData(`edit-budget-status/${id}`, "PUT", data);
    if (res && res?.status) {
      setIsLoading(false);
      toast.success("Activated Successfully");
      getmanagedbydata();
    } else {
      setIsLoading(false);
    }
  }

  const  handleMoreClick=(id)=>{
    setMoreId(id);
    setmoreamtmodal(true)
  }



  //for getting all managed by
  const getmanagedbydata = async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("fetch-budget", "GET", {});
    let apiData = []
    if (res && res?.status) {
      setIsLoading(false);
      apiData = res?.data?.map((item, i) => ({
        sl: i + 1,
        hrName: item?.hrName,
        department: item?.departmentName,
        // designation: item?.designation,
        startOf: item?.startOf,
        endOf: item?.endOf,
        hrEmployeeId: item?.hrEmployeeId,
        budget: item?.budget,
        moreAmount: item?.moreAmount,
        total: item?.total,
        // endOf: item?.endOf,
        action: <EditDeleteIcon
          onClickEdit={(e) => handleEdit(item)}
        // onClickDelete={(e) => handleDelete(item?._id)}
        />,
        more: <button onClick={() => handleMoreClick(item?._id)}
          type="button" className="btn btn-success floating-message"
          data-toggle="tooltip" data-placement="top" title="Click to change">
          More
        </button>,
        status: <div>
          {
            item?.isActive ?
              <button onClick={() => handleDeactive(item?._id)}
                type="button" className="btn btn-success floating-message"
                data-toggle="tooltip" data-placement="top" title="Click to change">
                Active
              </button> :
              <button type="button" className="btn btn-danger floating-message"
                onClick={() => handleActive(item?._id)}
                data-toggle="tooltip" data-placement="top" title="Click to change">
                Inactive
              </button>
          }
        </div>
      }));


    } else {
      setIsLoading(false);
    }

    setAllData(apiData);
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setformvalue(prev => ({ ...prev, [name]: value }));
  };



  //for validation functionality
  const validate = () => {
    if (!DepartmentName) {
      toast.error("DepartmentName is required");
      return true
    }
    // if (!DesignartionName) {
    //   toast.error("Designation is required");
    //   return true
    // }
    if (!HrName) {
      toast.error("HrName is required");
      return true;
    }

    return false
  }


  //for submit functionality
  const handleClick = () => {
    setaddmodal(!addmodal);
  };


  //for handle delete
  const handleDelete = (id) => {
    const del = async () => {
      setIsLoading(true);
      const res = await HttpClient.requestData("delete-managed-by/" + id, "PUT")
      if (res && res?.status) {
        setIsLoading(false);
        getmanagedbydata();
        toast.success("Deleted Successfully");
      } else {
        setIsLoading(false);
        toast.error(res?.message || "Something Wrong");
      }
    }

    DeleteConfirmModal(del);
  }


  //Edit
  const handleEdit = async (item) => {
    setIsEdit(true);
    seteditid(item?._id);
    // setDesignationName(item?.designationId);
    setHrEmployeeIdOptions([{ label: item?.hrEmployeeId, value: item?.hrEmployeeId }]);
    setDepartmentName(item?.departmentId);
    setHrName(item?.userId)
    setformvalue({
      budget: item.budget,
      startOf: item.startOf,
      endOf: item.endOf,
      // moreAmount: item?.moreAmount
    });
  }


  // edit submit
  const handleEditSubmit = () => {
    // e.preventDefault();
    setIsEditB(true);

    // if (!DepartmentName) {
    //   toast.error("DepartmentName is required");
    //   return false;
    // }
    // if (!DesignartionName) {
    //   toast.error("Designation is required");
    //   return false;
    // }
    // if (!HrName) {
    //   toast.error("HrName is required");
    //   return false;
    // }

    // const data = {
    //   "userId": HrName,
    //   "hrEmployeeId": hrEmployeeIdOptions[0]?.value,
    //   "departmentId": DepartmentName,
    //   // "budget": formvalue?.budget,
    //   "startOf": formvalue?.startOf,
    //   "endOf": formvalue?.endOf
    // }
    // setIsLoading(true);
    // const res = await HttpClient.requestData("edit-budget/" + editid, "PUT", data);
    // if (res && res?.status) {
    //   toast.success("Updated Successfully");
    //   setIsEdit(false)
    //   setIsLoading(false);
    //   setDepartmentName(null);
    //   setHrName("")
    //   setDepartmentDropdown([]);
    //   setHrDropdown([]);
    //   getmanagedbydata();
    //   setHrEmployeeIdOptions([]);
    //   setformvalue(initValue)

    // } else {
    //   toast.error(res?.message || "Something Wrong");
    //   setIsLoading(false);

    // }
  }


  //cancel edit
  const handleisCancelSubmit = (e) => {
    setIsEdit(false);
    // setDesignationName(null);
    setDepartmentName(null);
    setHrEmployeeIdOptions([]);
    setHrName("");
    setformvalue(initValue)
  }


  useEffect(() => {
    getDepartmentData();
    getDesignationData();
    getmanagedbydata();
  }, []);


  useEffect(() => {
    getHRData();
  }, []);

  // console.log("DepartmentName", DepartmentName, DesignartionName)

  return (
    <div>
      <CustomLoader loading={isLoading} />

      <div className="d-flex justify-content-end">

        <div className="form-header">
          <section className="piechartsBox_area">
            <div
              style={headLineStyle}
              className="page-headline"
            >
              Add Budget
              <hr />
            </div>
            <form>
              {/* Hr name */}
              <div className="row">
                {/* Hr ID */}
                <div className="col-md-6">
                  <label>HR Employee ID</label>
                  <Select
                    value={HrDropdown.find(option => option.employeeid === hrEmployeeIdOptions)}
                    // value={hrEmployeeIdOptions[0] || null}
                    // options={hrEmployeeIdOptions}
                    options={HrDropdown}
                    onChange={handleSelectHrName}
                    // isDisabled
                    placeholder="HR Employee ID"
                    components={animatedComponents}
                  />
                </div>


                <div className="col-md-6">
                  <label>HR Name</label>
                  <Select
                    // value={HrDropdown.find(option => option.value === HrName)}
                    value={HrName[0] || null}
                    // onChange={handleSelectHrName}
                    // options={HrDropdown}
                    placeholder="Select HR Name"
                    components={animatedComponents}
                  />
                </div>


              </div>

              {/* Hr department */}
              <div className="row">
                <div className="col">
                  <label htmlFor="formGroupExampleInput">Select Department Name<span className="text-danger">&nbsp;*</span></label>
                  <Select
                    value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
                    // onChange={handleSelectDepartName}
                    // options={DepartmentDropdown}
                    placeholder="Select Department"
                    components={animatedComponents}
                  />

                </div>


              </div>

              <div className="row">
                {/* Start FY */}
                <div className="col">
                  <label htmlFor="formGroupExampleInput">Start finance Year:
                    <span className="text-danger">&nbsp;*</span></label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    name="startOf"
                    onChange={handleChange}
                    value={moment(formvalue?.startOf)?.format("YYYY-MM-DD")}
                  />
                </div>
                {/* End FY */}
                <div className="col">
                  <label htmlFor="formGroupExampleInput">End finance Year:
                    <span className="text-danger">&nbsp;*</span></label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    name="endOf"
                    onChange={handleChange}
                    value={moment(formvalue?.endOf)?.format("YYYY-MM-DD")}
                  />
                </div>

              </div>


              <div className='row'>
                {/* Budget */}
                {isEdit &&

                  <div className="col">
                    <label htmlFor="formGroupExampleInput">Alloted Amount:
                      <span className="text-danger">&nbsp;*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      name="budget"
                      // onChange={handleChange}
                      value={formvalue?.budget}
                    />
                  </div>}

                {!isEdit &&

                  <div className="col">
                    <label htmlFor="formGroupExampleInput">Alloted Amount:
                      <span className="text-danger">&nbsp;*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      name="budget"
                      onChange={handleChange}
                      value={formvalue?.budget}
                    />
                  </div>}


                
              </div>


              {/* Button */}
              <div class="col-12 d-flex justify-content-end">
                {
                  !isEdit
                    ?
                    <button
                      type="button"
                      onClick={() => handleClick()}
                      class="btn btn-primaryb mt-3"
                      style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                    >
                      Add Budget
                    </button>
                    :
                    <>
                      <button
                        type="button"
                        onClick={() => handleEditSubmit()}
                        class="btn btn-primaryb mt-3"
                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                      >
                        Update Budget
                      </button>
                      <ImCross
                        style={{ fontSize: "20px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger "
                        onClick={(e) => handleisCancelSubmit(e)}
                      />
                    </>
                }
              </div>
            </form>

            <form>
              <div
                style={headLineStyle}
                className="page-headline"
              >
                View and manage Budget
              </div>
              <div>

                <DataTable
                  columns={columns}
                  data={AllData}
                  pagination
                  striped
                  className=" rounded mt-1"
                  customStyles={customStyles}
                />
              </div>
            </form>

          </section>
        </div>
      </div >

      {
        moreamtmodal && <Budgetmoremodal closemodal={setmoreamtmodal} moreId={moreId} getmanagedbydata={getmanagedbydata}/>
      }


      {
        addmodal && <BudgetModal
          closemodal={setaddmodal}
          formvalue={formvalue}
          hrEmployeeIdOptions={hrEmployeeIdOptions}
          DepartmentName={DepartmentName}
          HrName={HrName}
          getmanagedbydata={getmanagedbydata}
          setDepartmentName={setDepartmentName}
          setHrName={setHrName}
          setHrEmployeeIdOptions={setHrEmployeeIdOptions}
          setformvalue={setformvalue}
          DepartmentDropdown={DepartmentDropdown}
          setDepartmentDropdown={setDepartmentDropdown}
          setaddmodal={setaddmodal}
          HrDropdown={HrDropdown}
          setHrDropdown={setHrDropdown}
          initValue={initValue}
          validate={validate}

        />
      }


      {
        isEditB && <EditBudgetModal
          editid={editid}
          closemodal={() => setIsEditB(false)}
          formvalue={formvalue}
          hrEmployeeIdOptions={hrEmployeeIdOptions}
          DepartmentName={DepartmentName}
          HrName={HrName}
          getmanagedbydata={getmanagedbydata}
          setDepartmentName={setDepartmentName}
          setHrName={setHrName}
          setHrEmployeeIdOptions={setHrEmployeeIdOptions}
          setformvalue={setformvalue}
          DepartmentDropdown={DepartmentDropdown}
          setDepartmentDropdown={setDepartmentDropdown}
          setaddmodal={setaddmodal}
          HrDropdown={HrDropdown}
          setHrDropdown={setHrDropdown}
          initValue={initValue}
          validate={validate}

        />
      }

    </div >
  )
}

export default Budget