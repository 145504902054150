import React, { useEffect, useState } from 'react'
import CustomLoader from '../../CustomComponents/loader/CustomLoader'
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../CustomComponents/DeleteConfirmModal';
import { ImCross } from 'react-icons/im';


const headLineStyle = {
    textAlign: "center",
    fontSize: "30px",
    color: "#868e96",
    margin: "11px",
    fontWeight: "bold"
}

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },

};

const Designation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [Designationname, setDesignationname] = useState("");
    const [AllData, setAllData] = useState([]);
    const [Rewardname, setrewardname] = useState("");
    const [RewardPoint, setrewardPoint] = useState("");
    const [RewardID, setrewardID] = useState("");
    const [isEdit, setisEdit] = useState(false);


    const navigate = useNavigate()
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "15%"
        },
        {
            name: 'Designation Name',
            selector: row => row.DesignationName,
            width: "35%"
        },

        {
            name: 'Edit',
            selector: row => row.edit,
            width: "15%"
        },

        {
            name: 'Delete',
            selector: row => row.delete,
            width: "15%"
        }

    ];


    const getRewardPoints = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-designation", "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                DesignationName: item?.name,
                edit: <EditDeleteIcon
                    onClickEdit={(e) => handleEdit(item)}
                />,
                delete: <EditDeleteIcon
                    onClickDelete={(e) => handleDelete(item?._id)}
                />

            }));
        } else {
            setIsLoading(false);
        }

        setAllData(apiData);
    }

    //Delete Reward Point
    const handleDelete = (id) => {
        const del = async () => {
            setIsLoading(true);
            const res = await HttpClient.requestData("delete-designation/" + id, "PUT")
            if (res && res?.status) {
                setIsLoading(false);
                getRewardPoints();

                toast.success(" Deleted Successfully");
            } else {
                toast.error(res?.message || "Something Wrong");
            }
        }

        DeleteConfirmModal(del);
    }

    //Edit Reward
    const handleEdit = async (item) => {
        setisEdit(true)
        setDesignationname(item?.name)

        setrewardID(item?._id)
    }

    //Update reward
    const handleUpdate = async (e) => {
        e.preventDefault();
        if (Designationname === "") {
            toast.error("Input field blank or Designation is negative")
            return false;
        }

        const data = {
            "name": Designationname,

        }
        setIsLoading(true)
        // const res = await HttpClient.requestData("add-designation", "POST", data);
        const res = await HttpClient.requestData(`update-designation/${RewardID}`, "PUT", data);
        if (res && res?.status) {
            setisEdit(false)
            toast.success(res?.message);
            setIsLoading(false);
            setDesignationname("");
            getRewardPoints();

        } else {
            alert("error");
            toast.error("Please add valid data" || res?.message);
            setIsLoading(false);
        }
    }
    // const handleUpdate = async (e) => {
    //     e.preventDefault();
    //     if (Rewardname === "") {
    //         toast.error("Input field blank")
    //         return false;
    //     }
    //     const data = {
    //         "name": Rewardname,

    //     }
    //     setIsLoading(true)
    //     const res = await HttpClient.requestData(`update-designation/${RewardID}`, "PUT", data);
    //     if (res && res?.status) {
    //         setisEdit(false)
    //         toast.success(res?.message);
    //         setIsLoading(false);
    //         // alert("gg")
    //         setrewardname("");
    //         getRewardPoints();


    //     } else {
    //         toast.error(res?.message);
    //         setIsLoading(false);
    //     }


    // }

    //candel update
    const handleisCancelSubmit = (e) => {
        setisEdit(false)
        setrewardPoint('');
        setDesignationname('')
    }

    useEffect(() => {
        getRewardPoints();
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Designationname === "") {
            toast.error("Input field blank or Designation is negative")
            return false;
        }

        const data = {
            "name": Designationname,

        }
        setIsLoading(true)
        const res = await HttpClient.requestData("add-designation", "POST", data);
        if (res && res?.status) {
            getRewardPoints();
            toast.success(res?.message);
            setIsLoading(false);
            setDesignationname("");
            // navigate("/manage-designation")

        } else {
            alert("error");
            toast.error("Please add valid data" || res?.message);
            setIsLoading(false);
        }
    }

    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add Designation
                        </div>
                        <form className='mb-3'>
                            <div className="row">
                                <div className="col-md-8 ">
                                    <label htmlFor="formGroupExampleInput">Designation Name<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Designation Name"
                                        name=""
                                        value={Designationname}
                                        onChange={(e) => setDesignationname(e.target.value)}
                                    />
                                </div>

                                <div class="col-4 mt-3 d-flex justify-content-end  ">
                                    {
                                        !isEdit
                                            ?
                                            <button
                                                type="submit"
                                                onClick={(e) => handleSubmit(e)}
                                                class="btn btn-primaryb mt-3"
                                                style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                            >
                                                Add Data
                                            </button>
                                            :
                                            <div className="d-flex ">
                                                <button
                                                    type="submit"
                                                    onClick={(e) => handleUpdate(e)}
                                                    class="btn btn-primaryb mt-3 btn-grad"
                                                //style={{ background: "linear-gradient(595deg, rgb(100, 200, 300), rgb(10, 20, 40))", color: "#fff" }}
                                                >
                                                    Update Data
                                                </button>
                                                <ImCross onClick={(e) => handleisCancelSubmit(e)} style={{ fontSize: "25px", cursor: "pointer" }} className=" mx-3 mt-4 text-danger " />
                                            </div>
                                    }
                                </div>

                            </div>
                        </form>

                        <form>

                            <div>
                                <div
                                    style={headLineStyle}
                                    className="page-headline"
                                >
                                    Manage Designation Data
                                </div>

                                <DataTable
                                    columns={columns}
                                    data={AllData}
                                    pagination
                                    striped
                                    // className="rounded your-data-table"
                                    className="rounded your-data-table"
                                    customStyles={customStyles}
                                />
                            </div>
                        </form>

                    </section>
                </div>
            </div >




        </div >
    )
}

export default Designation